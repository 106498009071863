.lyrics-line {
  font-size: 26px;
  margin: 10px 0;
  font-weight: 500;
}

.lyrics-line:hover {
 opacity: 1;
}

.highlighted {
  font-size: 26px;
  font-weight: 600 !important;
}

.highlighted~div {
  opacity: 0.6;
}

.lyrics-container-not-result {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-family: 'Poppins';
}

.lyrics-container {
  text-align: left;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .lyrics-line {
    font-size: 14px;
    margin: 10px 0;
  }

  .highlighted {
    font-size: 16px;
  }

}