/* Card */
.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
}

.card-header,
.card-footer {
  border-color: #ebeef4;
  background-color: #fff;
  padding: 15px;
}

.user-webnexs .card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.card-title span {
  font-size: 14px;
  font-weight: 400;
}

.card-body {
  padding: 0 20px 20px 20px;
}

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}

.dashboard .nav-link {
  border-radius: 5px;
  font-weight: 500;
  background-color: #e1e1e1;
}

.dashboard .nav-link.active {
  background: rgb(255 255 255 / 20%);
  font-size: 16px;
  font-weight: 500;
}

.upload-video {
  background-color: #fafafa;
  padding: 10px;
  border-radius: 10px;
}

.iq-card {
  background: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 30px;
  border: none;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 5%);
  border-radius: 5px;
}
.rent_policsh {
  font-size: 12px;
}
@media (min-width: 576px) {
  .rent_payment_setting .modal-dialog {
    max-width: 600px;
    margin: 1.25rem auto;
  }
}
