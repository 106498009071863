.movieSwiper {
    aspect-ratio: 16/6;
}

.movieSwiper .bannerContainer {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.movieSwiper .vjs-theme-fantasy {
    object-fit: cover;
}

.bannerMoviePopDesc {
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    font-family: 'Poppins';
    height: 99px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

}

.movieSwiper .bannerButton {
    width: 80px;
    height: 55px;

}

.movieSwiper .swiperHeading {
    font-size: 26px;
}

.movieSwiper .circleAfter::after {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0;
    margin: auto;
    left: -10px;
}

.movieSwiper .bannerDescription {
    font-size: 17px;
    line-height: 27px;
    height: 113px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.movieSwiper .bannerSwiperBtn:not(:nth-child(1)) {
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.movieSwiper .overlayAbsoluteVideo {
    top: 0;
}


.movieSwiper .swiper-pagination-bullet {
    width: 23px !important;
    height: 5px !important;
    transform: scale(1) !important;
    border-radius: 3px !important;
}