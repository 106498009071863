.counter_timing {
    border-radius: 0.8em;
    -moz-border-radius: 0.8em;
    display: inline-block;
    line-height: 1.6em;
    margin-left: 20px;
    text-align: center;
    width: 1.6em;
}

.FiCheckCirclecolor {
    color: green;
}

.iyzicopaddingtoptop {
    padding-top: 100px;
}


@media (min-width:320px) {
    .counter_timing {
        border-radius: 0.8em;
        -moz-border-radius: 0.8em;
        display: inline-block;
        line-height: 1.6em;
        margin-left: 0px;
        text-align: center;
        width: 1.6em;
    }

    .SuccessfullyPayment {
        font-size: 18px;
        padding-bottom: 0px !important;
    }
}

@media (min-width:481px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width:641px) {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width:961px) {
    .counter_timing {
        border-radius: 0.8em;
        -moz-border-radius: 0.8em;
        display: inline-block;
        line-height: 1.6em;
        margin-left: 20px;
        text-align: center;
        width: 1.6em;
    }

    .SuccessfullyPayment {
        font-size: 32px;
        padding-bottom: 0px !important;
    }
}

@media (min-width:1025px) {
    .counter_timing {
        border-radius: 0.8em;
        -moz-border-radius: 0.8em;
        display: inline-block;
        line-height: 1.6em;
        margin-left: 20px;
        text-align: center;
        width: 1.6em;
    }

    .SuccessfullyPayment {
        font-size: 32px;
        padding-bottom: 0px !important;
    }
}

@media (min-width:1281px) {
    .counter_timing {
        border-radius: 0.8em;
        -moz-border-radius: 0.8em;
        display: inline-block;
        line-height: 1.6em;
        margin-left: 20px;
        text-align: center;
        width: 1.6em;
    }

    .SuccessfullyPayment {
        font-size: 32px;
        padding-bottom: 0px !important;
    }
}