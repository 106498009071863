.themeTwoFaq .accordion-button:focus,
.themeTwoFaq .accordion-button:not(.collapsed) {
    box-shadow: unset;
}

.themeTwoFaq .accordion-button::after {
    background-image: none;
    clip-path: polygon(82% 59%, 83% 70%, 50% 100%, 13% 70%, 13% 59%, 50% 88%);
}

.themeTwoFaq .accordion-button.genre::after {
    display: none;
}

.accorditionImage {
    width: 35px !important;
    height: 35px !important;
}

.faqSearchThemeTwo {
    top: 24px;
    left: 15px;
}

.themeTwoFaq input:focus-visible {
    outline: none;
}

.themeTwoFaq .question {
    font-size: 14px;
    font-weight: 400;
}

.themeTwoFaq .answer {
    font-size: 13px;
    line-height: 26px;
    font-weight: 300;
    opacity: 0.8;
}