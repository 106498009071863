/* footer start  */

.gradient-line {
  margin: 0 0 25px 0;
  display: block;
  border: none;
  height: 1px;
  background: #ffff;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%, #FFFFFF 46.15%, rgba(255, 255, 255, 0) 100%);
  ;
}

.footerlink a {
  color: #ffff;
  text-decoration: none;
}

.foolink {
  border: 1px solid #ffff;
}

.sicon i {
  color: #ffff;
  font-size: 24px;
  cursor: pointer;
}

.sicon i:hover {
  color: #FAB818;
  font-size: 24px;
  cursor: pointer;
}

.available_app {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;

}

.applink {
  align-items: center;
}

.applink img {
  width: 13%;
  cursor: pointer;
}

.termsandcondition a {
  color: #FFFFFF;
  text-decoration: none;
}

.termsandcondition a:hover {
  color: #FFFFFF;
  text-decoration: none;
}

.footerHeading {
  font-size: 24px;
  line-height: 24px;
  font-family: "Poppins";
  margin-bottom: 24px;
  font-weight: 600;
}

.footerLinks {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
}

.footerLinks:active {
  text-decoration: none;
}

.footerLinks.active {
  color: #fff;
}

.footerLinks:hover {
  text-decoration: none;
  color: #fff;
}



.footerSocialLink {
  width: 50px;
  height: 28px;
  color: #fff;
}

.copyRight {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
}

.footerContainer {
  /* background-color: #030328; */
}

.footerAppDownload {
  width: 140px;
  height: 50px;
}

.aboutText {
  font-size: 17px;
  line-height: 34px;
  font-weight: 400;
}

@media (min-width:768px) {
  .termsandcondition {
    display: flex;
  }
}

@media (max-width:550px) {
  .termsandcondition .foolink {
    display: none;
  }
}

/* footer end */