.livestreamsourceerror{
  color: red;
}
.accordionImage{
  width: 25px;
}

#accordionSocialExample .accordion-button:focus
{
  box-shadow: none;
}
#accordionSocialExample button[aria-expanded="true"] {
  background-color: transparent;
}