.addvideo .css-16r9pk3 {
  max-width: 1200px !important;
}

.addvideo .css-14yr603-MuiStepContent-root {
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-left: none !important;
}

.addvideo .css-ahj2mt-MuiTypography-root {
  margin: 0px !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.image_error_msg {
  color: red;
}

.addvideo .css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600 !important;
}

.addvideo .small,
small {
  font-size: 12px !important;
}

.addvideo .css-1mz1l2x-MuiStepContent-root {
  padding-left: 0px !important;
  margin-left: 0px !important;
}

.addvideo .css-1bw0nnu-MuiStep-root {
  padding-left: 0px !important;
}

.video .css-z7uhs0-MuiStepConnector-line {
  display: block !important;
  border-color: #bdbdbd;
  border-top-style: none !important;
  border-top-width: 1px;
}

.addvideo .css-1qm1lh {
  margin-bottom: 16px;
  display: flex;
  justify-content: end;
}

.addvideo .css-ascpo7-MuiStepLabel-root {
  flex-direction: column;
}

.video {
  display: block !important;
}

.addvideo #label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.addvideo .file-drag {
  height: 16rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

.addvideo #input-file-upload {
  justify-content: center;
  align-items: center;
  text-align-last: center;
}

.addvideo .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  background-color: #646464 !important;
  color: #fff !important;
}

.addvideo .css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #646464 !important;
  color: #fff !important;
}

.addvideo .css-1bw0nnu-MuiStep-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.searchWrapper {
  background: rgba(250, 250, 250, 1);
  border: 1px solid transparent;
  height: 45px;
  position: relative;
  font-size: 14px;
  width: 100%;
  -webkit-border-radius: 6px;
  height: 45px;
  border-radius: 4px;
  /* margin-bottom: 20px; */
  padding-left: 10px;
  font-weight: 400;
  line-height: 19px;
  color: #646464 !important;
  margin-top: 8px;
}

.videoupload {
  margin-bottom: 12px;
}


.formcreatable .css-13cymwt-control {
  background: rgba(250, 250, 250, 1);
  border: 1px solid transparent;
  height: 45px;
  position: relative;
  font-size: 14px;
  width: 100%;
  -webkit-border-radius: 6px;
  height: 45px;
  border-radius: 4px;
  /* margin-bottom: 20px; */
  padding-left: 10px;
  font-weight: 400;
  line-height: 19px;
  color: #646464 !important;
  margin-top: 8px;
}

.file-e-paper {
  /* height: 100px; */
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 5px;
  border-style: dashed;
  text-align: center;
  border-color: #cbd5e1;
  color: #000000;
  background-color: transparent;
}

.intro_time_error {
  font-size: 12px;
  color: red;
  font-weight: 600;
}