.profile-chooser-title {
  font-size: 25px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: center;
}

.profile-chooser-list {
  display: flex;
  justify-content: center;
}

.profile-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 15px;
  margin: 0 10px;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.profileChoose {
  transform: scale(1);
  transition: transform 0.3s linear;

}

.profileChoose:hover {
  transform: scale(1.3);

}

.Avatar {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  overflow: hidden;
  /* Ensure square avatars do not exceed dimensions */
}

.Avatar2 {
  width: 110px;
  height: 110px;
  border-radius: 60px;
  /* transition: transform 0.6s ease-in-out; */
  /* overflow: hidden;  */
}

.Avatar3 {
  width: 100px;
  height: 100px;
  border-radius: 60px;
  /* transition: transform 0.6s ease-in-out; */
  /* overflow: hidden;  */
}

.Avatar2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-item p {
  font-size: 14px;
  font-weight: bold;
}

.profile-item.selected {
  background-color: #5d09e5;
  color: white;
}

.profile-item.selected p {
  color: white;
}

.hide {
  display: none;
}

/* .mydiv:hover+.hide {
  background-color: #e50914;
  display: block;
} */

.edit-button {
  margin-top: 10px;
  background-color: #e50914;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.add {
  padding: 50px 10px;
}

/* Style for the dropdown container */
.profile-Avatar {
  position: relative;
  display: inline-block;
}

/* Style for the dropdown button */
.Avatar-image {
  /* background-color:  rgb(240, 240, 240) ; */
  color: #000000;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

/* Style for the dropdown menu */
.edit-profile-user {
  display: none;
  left: 66px;
  position: absolute;
  /* background-color: #f9f9f9; */
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  z-index: 1;
  right: 0;
  top: -16px;
  width: 10px;
  /* box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5); */
}

/* Show the dropdown menu when the button is clicked */
.profile-Avatar:hover .edit-profile-user {
  display: block;
}

/* Style for dropdown menu items */
.edit-profile-user a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* .shadow {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.7) !important;
} */

.avatarProfile {
  width: 120px !important;
  height: 120px !important;
}

.avatarProfile {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22)
}

.profileImageUpdateMulti {
  max-width: 130px;
  height: 130px !important;
  width: 130px;
}

.profileImageUpdateMultiImage {
  width: 110px;
  height: 110px !important;
  margin: 0 auto;
}

.profileImageUpdateMulti:hover:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 1;
  content: "";
  opacity: 0.7;
}

.profileImageUpdateMulti:hover .uploadIcon {
  display: block;
}

.profileImageUpdateMulti:hover .uploadIcon {
  display: block;
}

.uploadIcon {
  width: 20px;
  height: 20px;
  display: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 2;
}

.profileImageLens {
  right: 20px;
  bottom: -10px;
  z-index: 2;
}

.profileInput {
  top: 0;
  opacity: 0;
  z-index: 3;
}

.iconEditProfile {
  top: 0;
  right: 0;
  z-index: 2;
}

.titleProfile {
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
}

.textProfileWidth {
  width: 90px;
  word-wrap: break-word;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

}


.switchBox {
  position: relative;
  display: inline-block;
  width: 53px;
  height: 26px;
}

.switchBox input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderBox {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.6s;
  transition: all 0.4s;
}

.sliderBox.round {
  border-radius: 34px;
}

.sliderBox.round:before {
  border-radius: 50%;
}

input:checked+.sliderBox {
  background-color: #2196f3;
}

input:focus+.sliderBox {
  box-shadow: 0 0 1px #2196f3;
}

.sliderBox:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: all 0.4s;
}

input:checked+.sliderBox:before {
  left: 29px;
}