.adlist {
    font-size: 2.0em;
    line-height: 1.200em;
    font-family: 'Poppins';
    font-weight: 400;
}

.advertiserHeading {
    font-size: 25px;
    line-height: 30px;
    font-weight: 600;
}

.advertiserSubHeading {
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
}
.uploadBoxAdvertiser{
    width: 80px;
    height: 80px;
    object-fit: contain;
    
}
.adsText {
    font-size: 1.3em;
    font-weight: 400;
    font-family: 'Poppins';
    /* padding: 36px; */
}

.adsSmallText {
    font-size: 1.3em;
    font-weight: 400;
    font-family: 'Poppins';
}

.adsHeaderText {
    font-size: 2.2em;
    line-height: 1.200em;
    font-family: 'Poppins';
    font-weight: 600;
}

.bootstrapTableAds table>thead>tr>th {
    border: none;
    font-size: 16px;
    font-family: "Poppins" !important;
    padding: 10px;
    width: auto;
    width: 130px;
    font-weight: 300;
}

.advertiserSelect {
    width: 340px;
}

.advertiserLabel {
    font-size: 25px;
}

.advertisercheckBox {
    justify-content: space-around;
}

@media screen and (max-width: 768px) {
    .adlist {
        font-size: 1.4em;
        line-height: 1.200em;
        font-family: 'Poppins';
        font-weight: 400;
        padding: 10px;
    }

    .adsText {
        font-size: 1.3em;
        font-weight: 400;
        font-family: 'Poppins';
        padding: 10px;
    }

    /* .advertiserSelect{
        width: 280px; 
        padding: 10px; 
      } */
}