@import url('./Default-theme.css');

.admin-title {
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
    font-family: 'Poppins';
}

.admin-sub-title {
    font-family: 'Poppins';
    font-size: 14px;
    line-height: 15px;
    font-weight: 600;
    word-wrap: break-word;
}

.admin-button-text {
    font-family: 'Poppins' !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 20px !important;
    color: #fff;
}

.css-1nmdiq5-menu {
    font-family: 'Poppins' !important;
}

.admin-input-title {
    font-size: 15px;
    font-weight: 600;
}

.admin-input-description {
    font-size: 13px !important;
    font-family: 'Poppins' !important;
    font-weight: 400;
}

.admin-modal-title {
    font-size: 18px;
}

.trial-border-bottom {
    border-bottom: 2px solid rgb(45 48 56);
}

.trial-border-all {
    border: 2px solid rgb(45 48 56);
}

.mandatorySimple::after {
    content: '*';
    color: red;
}

.thumbnails-upload-images {
    width: 100%;
    height: 95px;
}

.imagedrop {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border-style: dashed;
    border-color: #cbd5e1;
    cursor: pointer;
}

.imagedropcopy {
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border-style: dashed;
    border-color: #cbd5e1;
    cursor: pointer;
}

.imagedropcopyimage {
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border-style: dashed;
    border-color: #cbd5e1;
    width: 100%;
    cursor: pointer;
}


.file-draganddrop {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    background-color: transparent;
}


.overlay-icon {
    font-size: 25px;
    color: white;
}

label {
    font-size: 16px;
}

textarea.rs-input {
    height: 100px;
    font-family: 'Poppins';
}

.bootstrapTable table>thead>tr {
    border: none;
}

.bootstrapTable table>thead>tr>th {
    border: none;
    font-size: 16px;
    font-family: "Poppins" !important;
    padding: 10px;
    width: 130px;
    font-weight: 500;
    position: relative;
    text-wrap: nowrap;
}

.bootstrapTable table>tbody>tr>td {
    white-space: normal;
    word-break: break-all;
    border: none;
    font-size: 15px;
    font-family: "Poppins" !important;
    padding: 10px;
    width: 130px;
    vertical-align: middle;
}

.bootstrapTable.multi-delete-width table>thead>tr>th:nth-child(1) {
    width: 4%;
}

.bootstrapTable .video-width-table {
    width: 200px;
}

.videoWidth>th:nth-child(2) {
    width: 30%;
    position: relative;
}

.videoWidth>th:nth-child(2) span {
    position: absolute;
    right: 0;
}

.videoWidth>th {
    width: 100px;
}

.rightVideoTitle {
    width: 200px;
}

.view {
    color: #000000;
}

.edit {
    color: #000000;
}

.delete {
    color: #000000;
}

.fa-star {
    color: gold;
}

.admin-list-table {
    font-family: "Poppins";
}

.bg-color {
    background-color: var(--bg-color);
}

.bg-opacity-color {
    background-color: var(--bg-opacity-color);
}

.bootstrapTable {
    width: 100%;
    overflow-x: auto;
}

.bootstrapTable::-webkit-scrollbar {
    height: 3px;
}

.modal.packageModel {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.editdropdown {
    position: relative;
    display: inline-block;
    z-index: 9;
}

.editdropdown-button {
    color: #000000;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.editdropdown-menu {
    display: none;
    left: -163px;
    top: 0;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 185px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.editdropdown:hover .editdropdown-menu {
    display: block;
}

.editdropdown-menu a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.commonActionPadding {
    padding: 12px 16px;
    cursor: pointer;
}

.adminHeadContainer {
    min-height: calc(100vh - 120px);
}

.toggleAdminButton {
    visibility: hidden;
}

.adminIcon {
    width: 20px;
    height: 20px;
}

.adminSideBarLeft {
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 990;
    height: 100vh;
    width: 275px;
    overflow-y: scroll;
}

.adminSideBarLeft::-webkit-scrollbar {
    width: 2px;
}

.adminSideBarLeft .adminImageLink {
    position: sticky;
    top: 0px;
    z-index: 1;
}

.adminSideBarRight {
    margin-left: auto;
    width: calc(100% - 275px);
}

.advertiserHeader {
    position: sticky;
    top: 0;
    z-index: 991;
}

.profile {
    width: 50px !important;
    height: 50px !important;
    object-fit: cover;
    border-radius: 3px;
}

.exportIcon {
    width: 22px;
    height: 22px;
}

.form-control.filter {
    max-width: 270px;
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: 11px 13px;
    padding: 8px 37px;
    background-image: url("../assets/icons/lens-icons.webp");
}

.form-control.filter:focus {
    box-shadow: none;
}

.table-heading {
    font-size: 15px;
}

.table-heading:hover {
    text-decoration: underline;
    color: #ccc;
}

.table-subheading {
    font-size: 12px;
    color: #ccc;
}

.sliderBtnText {
    font-size: 13px;
    font-family: 'Poppins' !important;
}

.sliderBtnText:hover {
    text-decoration: underline;
}

.sliderBtnText:focus {
    border: none;
}

.text-decoration-hover:hover {
    text-decoration: underline;
    text-decoration-color: #fff;
}

.PUBLISHED_active {
    background: #d3ffd3;
    color: green;
    font-weight: 600;
    font-size: 13px;
    border-radius: 20px;
    width: 120px;
}

.UNPUBLISHED_active {
    background: #ffd9d9;
    color: red;
    font-weight: 600;
    font-size: 13px;
    border-radius: 20px;
    width: 120px;
}

.DISABLED_active {
    background: #ffea008a;
    color: #000000;
    font-weight: 600;
    font-size: 13px;
    border-radius: 20px;
    width: 120px;
}

.svgIcon {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0.75px;
}

.sideMenu.active {
    border-radius: 8px;
    color: inherit;
}

/* .sideMenu.active svg,
.sideMenu.active span {
    color: #345afa !important;
} */

.headingActive {
    border-radius: 8px;
}

.sideMenu span {
    font-size: 14px;
    font-weight: 500;
}

.sideMenu:active,
.sideMenu:focus,
.sideMenu:hover {
    color: inherit;
    text-decoration: none !important;
}

.adminSideDropDown {
    display: none;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 68px;
    background: #fff;
    width: 160px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 10px 11px -2px #0000002e;
    z-index: 2;
}

.adminDropDownContainer:hover .adminSideDropDown {
    display: flex;
}

.adminSideDropDown>li>a {
    color: #000;
    font-size: 15px;
}

.adminSideDropDown>li>a>svg {
    color: #000;
}

.rightSideButton {
    border-radius: 30px;
}

.rowCheckboxDrag {
    width: 4%;

}


.radio-option {
    width: 15px;
    height: 15px;
    vertical-align: middle;
}

.css-1jqq78o-placeholder {
    font-family: 'Poppins' !important;
}

.custom-close-btn {
    width: 20px;
    height: 20px;
    fill: currentColor;
}

.category-order-title {
    padding: 10px;
    font-size: 16px;
    white-space: normal;
    word-break: break-all;
    border: none;
}

.eyepasswordusers {
    position: absolute;
    right: 1px;
    top: 1px;
    padding: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}


/* stepper style */
.stepLineNone .MuiStepConnector-lineHorizontal {
    display: none;
}

.MuiStepConnector-lineVertical {
    display: none !important;
}

/* stepper end */



.clearBtn {
    top: 10px;
    position: absolute;
    right: 0;
    background: transparent;
}

.filterContainer {
    max-width: 270px;
    width: 100%;
    position: relative;
}

.filter:focus {
    box-shadow: none !important;
}


.sortable::after {
    content: "\2193";
    color: #ccc;
    padding: 0px 9px;
    position: absolute;
    right: 12px;
    top: 3px;
    font-size: 18px;
}

.sortable::before {
    content: "\2191";
    color: #ccc;
    position: absolute;
    right: 12px;
    top: 3px;
    font-size: 18px;

}

th[aria-label='Audios sort asc'].sortable::before {
    color: #0d6efd;
}

th[aria-label='Audios sort desc'].sortable::after {
    color: #0d6efd;
}

th[aria-label='Access sort asc'].sortable::before {
    color: #0d6efd;
}

th[aria-label='Access sort desc'].sortable::after {
    color: #0d6efd;
}

th[aria-label='Videos sort asc'].sortable::before {
    color: #0d6efd;
}

th[aria-label='Videos sort desc'].sortable::after {
    color: #0d6efd;
}

th[aria-label='Upload by sort asc'].sortable::before {
    color: #0d6efd;
}

th[aria-label='Upload sort desc'].sortable::after {
    color: #fff;
}

.react-bootstrap-table-page-btns-ul {
    justify-content: end;
}

/* slider css start */

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: all 0.4s;
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #2196f3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196f3;
}

.slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: all 0.4s;
}

input:checked+.slider:before {
    left: 26px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 20px;
}

/* slider css end */

@media screen and (max-width:576px) {
    .adminIcon {
        width: 20px;
        height: 20px;
    }
}

@media screen and (max-width:991px) {
    .toggleAdminButton {
        visibility: visible;
    }

    .adminSideBarLeft {
        left: -100%;
        transition: left 0.4s linear;
        width: 100%;
    }

    .adminSideBarLeft.channel-active {
        left: 0;
    }

    .adminSideBarRight {
        width: 100%;
    }

    .adminSideBarLeft.advertiser-active {
        left: 0;
    }
}