@media screen and (max-width:768px) {

  .play-btn,
  .add-btn {
    padding: 7px 7px;
    font-size: 14px;
  }
}

/* Subscription */
.sub-name2 {
  color: white;
  font-size: 30px;
  font-family: "Poppins";
}

.back-subscription {
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
}

.season {
  font-size: 35px;
  font-family: "Poppins";
  line-height: 53px;
  padding-left: 40px;
  color: white;
}

.seasonname {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.like-active {
  fill: #fff;
}

.dislike-active {
  fill: #fff;
}


/* Artist Tabs */
.tab-container {
  width: 600px;
}

.tab-container2 {
  width: 100%;
}

.tabs {
  display: flex;
  justify-content: space-between;
}

.tabs2 {
  display: flex;
  justify-content: start !important;
  /* justify-content: space-between; */
}

.tab2Text {
  font-size: 18px;
  font-weight: 500;
  cursor: pointer !important;
}

.tabs button {
  cursor: pointer !important;
  width: 100%;
  padding: 8px;
  margin-right: 5px;
  color: black;
  border-radius: 5px;
}

/* .tabs button:disabled {
  background-color: inherit;
 color: white;
} */

/*--------------------------*/
.favorites-slider .slick-list,
/* #top-ten-slider-nav .slick-list {
	overflow: visible;
	padding-bottom:  !important;
} */

.favorites-slider li.slide-item {
  float: left;
  width: 100%;
}

.favorites-slider .slick-list {
  overflow: visible;
}

.favorites-slider.li.slide-item {
  position: relative;
  padding: 10px 5px;
}

.artist-images {
  position: relative;
  transition: all 0.45s ease 0s;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: all 0.6s ease 0s;
  -webkit-transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  border-radius: 10px;
}

.img-fluid2 {
  width: 100%;
  height: 126px;
  object-fit: cover;
}

.favorites-slider.li.slide-item:hover .artist-images {
  overflow: visible;
}

.artist-images .artist-box:hover {
  position: relative;
}

.artist-images:hover {
  z-index: 99;
  transform: scale(1.1);
  /* transform-origin: 40% 50%; */
  transition: all 0.6s ease 0s;
  -webkit-transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.9);
  /* border-radius: 10px !important; */
}

.artist-text {
  color: white;
  font-family: "Poppins";
}

/*.block-social-info { position: absolute; top: 0; left: auto; bottom: 0;right: 25px; z-index: 999;display: flex; align-items: center; opacity: 0; }*/

.music-play-lists li {
  height: 30px;
  width: 30px;
  line-height: 35px;
  text-align: center;
  background: #ed563c;
  /*background: rgba(255, 255, 255, 0.30);*/
  border-radius: 50%;
  display: flex;
  justify-content: space-around;
  -webkit-display: flex;
  -moz-display: flex;
  -o-display: flex;
  align-items: center;
  list-style: none;
}

.music-play-lists span {
  position: relative;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 26px;
  font-size: 12px;
  text-align: center;
  /*background: var(--iq-white);*/
  color: #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 0 auto;
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  -o-display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
  cursor: pointer;
}

.music-play-lists li:hover span {
  background: var(--iq-primary);
  color: var(--iq-white);
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
}

.favorites-slider.artist-description {
  position: absolute;
  left: 0;
  right: 0;
  width: 20px;
  margin: 0 auto;
  top: 26%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  opacity: 0;
  font-size: 12px;
  color: var(--TEXT_COLOR_L0);
}

.b1 {}

.favorites-slider.artist-description>h6 {
  font-size: 1.5em;
}

.artist-images::before {
  /* border-radius: 10px;
} */
}

.artist-images:hover::before {
  opacity: 1;
  z-index: 9;
}

.artist-description:hover {
  animation: fadeIn 0.6s ease-in-out;
  opacity: 1;
}

.favorites-slider.li.slide-item:hover .artist-social-info {
  animation: fadeIn 0.6s ease-in-out;
  opacity: 1;
}

.hover-buttons .btn {
  padding: 5px 15px;
  font-size: 12px;
}

/*li.slide-item.slick-current:hover .block-images{ transform: scale3d(1.3, 1.3, 1) translate3d(11%, 0, 0) perspective(500px); }*/
.favorites-slider.artist-images .hover-buttons {
  margin-top: 5px;
}

.artist-social-info .music-play-lists li {
  width: 40px;
  height: 40px;
}

.artist-social-info .music-play-lists span {
  width: 30px;
  height: 30px;
}

.artist-social-info .music-play-lists span i {
  font-size: 15px;
}

.favorites-contens {
  padding: 10px;
}

.artist-image {
  height: 132px;
  width: 234px;
  border-radius: 4px;
  margin-right: 10px;
  transition: transform 0.3s;
  background-size: cover;
}

.artist-image:hover {
  transform: scale(1.2);
  transition: transform 0.5s;
  position: relative;
}

.artist-block {
  opacity: 0;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  /* background: linear-gradient(0deg, #101b35 30%, rgba(255, 255, 255, 0) 80%); */
  transition: opacity 0.5s;
}

.artist-block:hover {
  opacity: 1;
  transition: opacity 0.5s;
}

.artist-block div {
  margin: 0px 20px;
  overflow-y: hidden;
  font-size: 50px;
  line-height: 1.5em;
  font-weight: 600 !important;
}

/* stripe page rent page  */

.rent_policsh {
  font-size: 12px;
  color: #000000;
}

.rent_policsh_color {
  color: #000000;
}

.live_Indicator {
  width: 65px;
  height: 15px;
  border-radius: 6px;
  right: 4%;
  position: absolute;
  z-index: 4;
  bottom: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.moreLikeText {
  font-family: "Poppins";
  font-size: 35px;
  font-weight: 600;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: left;

}

.tabHeading {
  font-size: 18px;
  line-height: 33px;
  font-weight: 600;
}

.tabHeading.highlightTabColor::after {
    bottom: -4px;
    height: 4px;
    width: 100%;
    position: absolute;
    content: "";
    left: 0;
    border-radius: 20px;
}