.musicHomePageSwiper .swiper-slide {
    height: 250px;
    border-radius: 0.375rem;
}

.musicCategorySwiper .swiper-slide {
    aspect-ratio: 16/9;
}

.musicAlbumSwiper .swiper-slide {
    height: 200px;
    border-radius: 0.375rem;
}

.musicHomePageSwiper .swiper-button-prev::after,
.musicHomePageSwiper .swiper-button-next::after,
.musicCategorySwiper .swiper-button-prev::after,
.musicCategorySwiper .swiper-button-next::after,
.musicAlbumSwiper .swiper-button-prev::after,
.musicAlbumSwiper .swiper-button-next::after {
    font-size: 22px;
    font-weight: bold;
}

.musicHomePageSwiper .swiper-button-prev,
.musicHomePageSwiper .swiper-button-next,
.musicCategorySwiper .swiper-button-prev,
.musicCategorySwiper .swiper-button-next,
.musicAlbumSwiper .swiper-button-prev,
.musicAlbumSwiper .swiper-button-next {
    top: 0;
    color: #fff;
    z-index: 3;
    height: 100%;
}


.musicImageDetailsHeight {
    height: 150px !important;
}

.musicDetailsImage {
    height: 150px !important;
}

.musicImageDetailsSmallHeight {
    height: 100px !important;
}

.musicDetailsSmallImage {
    height: 100px !important;
}

.musicDetailsSwiper .swiper-button-next:after,
.musicDetailsSwiper .swiper-button-prev:after {
    font-size: 23px;
    color: #fff;
}
