/* signin start */

.signin input {
  font-size: 18px;
  height: 50px;
}

.signin h1 {
  color: #ffff;
  font-style: normal;
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 54px;
  align-items: center;
  text-align: center;
   font-family: "Poppins";
  color: #000000;
}

.box-signin {
  background: #ffff;
  width: 550px;
  /* border-radius: 6px; */
}

.signin button.button {
  background: #111727;
  color: #ffff !important;
   font-family: "Poppins";
  font-style: normal;
  font-size: 30px;
  font-weight: 600;
}

.signin button.button :hover {
  background: #111727;
  color: #ffff !important;
   font-family: "Poppins";
  font-style: normal;
  font-size: 30px;
  font-weight: 600;
}

.eyepasswordContentsignin {
  position: absolute;
  padding: 35px;
  right: 32%;
  color: #d0cfcf;
  font-size: 18px !important;
}
.eyepasswordContentsignin :hover {
  right: 31%;
  color: #fab818;
  font-size: 18px !important;
}
.box-signin a {
  text-decoration: none;
}
.forgot {
  text-align: start;
  font-family: "Poppins" ;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-decoration: none;
  color: blue;
}

/* .signin i.rounded {
  font-size: 30px;
  width: 40px;
  margin: 5px;
  color: #929191;
  height: 43px;
} */

/* 
.signin i.rounded :hover {
  font-size: 30px;
  width: 40px;
  margin: 5px;
  color: #FAB818;
  height: 43px;
} */

.rounded {
  border: 1px solid;
  border-radius: 50% !important;
  padding: 1%;
}

.accountsign {
  color: #676666;
}

.accountsign span {
  color: #fab818;
  text-decoration: underline;
}

h6.social-login {
  font-family: "Poppins" ;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #676666;
}

.siginerror {
  color: red;
  margin-left: 13%;
  margin-top: 1%;
  display: flex;
  font-family: "Poppins" ;
}

/* signin end */

/* Mobile view  */

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
  .eyepasswordsignin {
    position: absolute;
    padding: 35px;
    right: 12%;
    font-size: 18px !important;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .eyepasswordsignin {
    position: absolute;
    padding: 35px;
    right: 22%;
    font-size: 18px !important;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .eyepasswordsignin {
    position: absolute;
    padding: 35px;
    right: 18%;
    font-size: 18px !important;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .eyepasswordsignin {
    position: absolute;
    padding: 35px;
    right: 26%;
    font-size: 18px !important;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .eyepasswordsignin {
    position: absolute;
    padding: 35px;
    right: 31%;
    font-size: 18px !important;
  }
}
@media screen and (min-width: 1400px) {
  .eyepasswordsignin {
    position: absolute;
    padding: 35px;
    right: 33%;
    font-size: 18px !important;
  }
}
@media screen and (min-width: 1600px) {
  .eyepasswordsignin {
    position: absolute;
    padding: 35px;
    right: 37%;
    font-size: 18px !important;
  }
}
@media screen and (min-width: 1900px) {
  .eyepasswordsignin {
    position: absolute;
    padding: 35px;
    right: 40%;
    font-size: 18px !important;
  }
}
