.ThemeTwoSignIcon {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.themeTwoHead {
    font-family: "Poppins";
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    text-align: center;
}

.ThemeTwoCheckbox {
    width: 15px;
    height: 15px;
}

.themeTwoBannerWrapper {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.7));
    background-position: top left;
    background-size: 100% 800px;
    background-repeat: no-repeat;
}

.codeWidth {
    width: 50px;
    height: 50px;
}
.themeTwoBannerWrapper .eyeButton{
    position: absolute;
    top: 6px;
    right: 0;
}