:root {
    --large-lg-heading: 58px;
    --large-md-heading: 52px;
    --large-sm-heading: 48px;
    --mid-lg-heading: 42px;
    --mid-md-heading: 36px;
    --mid-sm-heading: 30px;
    --small-lg-heading: 24px;
    --small-md-heading: 18px;
    --small-sm-heading: 14px;
}