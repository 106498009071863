@media screen and (min-width:320px) {
    .viewAllMusic {
        font-size: var(--small-sm-heading);
    }

    .small-sm-heading {
        font-size: var(--small-sm-heading);
    }

    .navHeadingMusic {
        font-size: var(--small-md-heading);
        font-weight: bold;
    }

    .themeTwoHeader {
        padding: 8px;
    }

    .swiperThemeTwo .bannerWrapper {
        padding: 15px;
    }



}

@media screen and (min-width:425px) {
    .viewAllMusic {
        font-size: var(--small-md-heading);
    }

    .navHeadingMusic {
        font-size: var(--small-lg-heading);
        font-weight: bold;
    }

    .listPanelPrice .priceText {
        width: 61px;
        text-align: center;
    }

    .listPanelPrice {
        width: 82px !important;
    }
}

@media screen and (min-width:768px) {
    .small-md-heading {
        font-size: var(--small-md-heading);
    }

    .themeTwoHeader {
        padding: 7px 30px;
    }

    .swiperThemeTwo .bannerWrapper {
        padding: 50px 30px;
    }

}

@media screen and (min-width:992px) {
    .small-lg-heading {
        font-size: var(--small-lg-heading);
        line-height: calc(var(--small-lg-heading) * 2);
    }

    .profileThemeTwo {
        padding: 17px 30px;
    }

    .swiperThemeTwo .bannerWrapper {
        padding: 90px 60px;
    }
}

@media screen and (min-width:1024px) {
    /* .swiperThemeTwo .swiper-pagination-bullet {
        width: calc(100% / 5);
        height: 150px !important;
    } */
}

@media screen and (min-width:1200px) {

    /* .swiperThemeTwo .swiper-pagination-bullet {
        width: 230px;
        height: 150px !important;
    } */
    .swiperThemeTwo .swiper-pagination-bullet {
        width: 180px;
        height: 100px !important;
    }

    .homePageSwiper .swiper-slide-next+.swiper-slide+.swiper-slide+.swiper-slide+.swiper-slide .homeSwiperContainer {
        right: 24px;
        left: auto;
    }

}

@media screen and (min-width:1440px) {}

@media screen and (max-width:768px) {
    .themeTwoNavMobileFixed {
        position: fixed;
        top: 0;
        left: -100%;
        width: 100%;
        padding: 75px 13px !important;
        height: 100vh;
        flex-direction: column;
        justify-content: unset !important;
        transition: left 0.6s linear;

    }

    .themeTwoNavMobileFixed.active {
        left: 0;
    }

    .homePageSwiper .swiper-button-next {
        display: none;
    }

    .homePageSwiper .swiper-button-prev {
        display: none;
    }

    .bootstrapTable .react-bootstrap-table-pagination {
        gap: 14px;
        margin: 0 !important;
    }

    .hover-card-mobile-none {
        display: none !important;
    }

    .languageText {
        font-size: 16px;
    }
}

@media screen and (max-width: 425px) {
    .homeSwiperContainer {
        width: 267px;
    }

    .homePageSwiper .swiper-slide:hover .homeSwiperContainer {
        transform: scale(1.1);
    }

    .homePageSwiper .swiper-slide-active .homeSwiperContainer {
        left: 7px;
    }

    .homePageSwiper .swiper-slide:nth-last-child(2) .homeSwiperContainer {
        right: 7px;
    }

    .homePageSwiper .swiper-slide:last-child .homeSwiperContainer {
        right: 7px;
    }

    .homePageSwiper.categorySizeSmall .swiper-slide {
        height: 165px !important;
    }

    .swiperHeading {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 6px;
    }

    .homePageSwiper .swiper-slide {
        height: 240px;
    }

    .loaderText {
        font-size: 15px;
        line-height: 20px;
    }
}


@media (min-width: 1920px) and (max-width: 2560px) {
    .homePageSwiper .swiper-slide {
        width: 100%;
        height: 400px;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        z-index: 0;
    }

    .homeSwiperContainer {
        height: 380px;
        width: 250px;
        top: 10px;
        left: 0;
    }

    .homePageSwiper .swiper-slide:nth-child(6) .homeSwiperContainer {
        right: 10px;
        left: auto;
    }

    .desktopview-category-image {
        height: 225px !important;
    }
}

@media screen and (min-width: 1600px) {
    .homePageSwiper .swiper-slide {
        width: 100%;
        height: 350px;
        text-align: center;
        font-size: 18px;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        z-index: 0;
    }

    .homeSwiperContainer {
        min-height: 325px;
        width: 250px;
    }

    .homePageSwiper .swiper-slide:nth-child(6) .homeSwiperContainer {
        right: 10px;
        left: auto;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
    .homePageSwiper .swiper-slide-next+.swiper-slide+.swiper-slide+.swiper-slide .homeSwiperContainer {
        right: 38px;
        left: auto;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .homePageSwiper .swiper-slide-next+.swiper-slide+.swiper-slide .homeSwiperContainer {
        right: 38px;
        left: auto;
    }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
    .homePageSwiper .swiper-slide-next+.swiper-slide .homeSwiperContainer {
        right: 38px;
        left: auto;
    }
}

@media screen and (min-width: 320px) and (max-width: 576px) {
    .homePageSwiper .swiper-slide-next .homeSwiperContainer {
        right: 13px;
        left: auto;
    }
}