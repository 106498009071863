.draganddrop {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  cursor: pointer;
}
.addvideo .css-16r9pk3 {
  max-width: 1200px !important;
}
.addvideo .css-14yr603-MuiStepContent-root {
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-left: none !important;
}
.addvideo .css-ahj2mt-MuiTypography-root {
  margin: 0px 0px 0px 0px !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
.image_error_msg {
  color: red;
}
.video_source .css-m5vj9m-MuiStepper-root {
  display: block !important;
}

.addvideo .css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600 !important;
}
.addvideo .small,
small {
  font-size: 12px !important;
}

.addvideo .css-1mz1l2x-MuiStepContent-root {
  padding-left: 0px !important;
  margin-left: 0px !important;
}

.video .css-z7uhs0-MuiStepConnector-line {
  display: block;
  border-color: #bdbdbd;
  border-top-style: none !important;
  border-top-width: 1px;
}
.addvideo .css-1qm1lh {
  margin-bottom: 16px;
  display: flex;
  justify-content: end;
}
.addvideo .css-ascpo7-MuiStepLabel-root {
  flex-direction: column;
}

.addvideo #label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.addvideo .file-drag {
  height: 16rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}
.addvideo #input-file-upload {
  justify-content: center;
  align-items: center;
  text-align-last: center;
}

.searchWrapper {
  background: rgba(250, 250, 250, 1);
  border: 1px solid transparent;
  height: 45px;
  position: relative;
  font-size: 14px;
  width: 100%;
  -webkit-border-radius: 6px;
  height: 45px;
  border-radius: 4px;
  /* margin-bottom: 20px; */
  padding-left: 10px;
  font-weight: 400;
  line-height: 19px;
  color: #646464 !important;
  margin-top: 8px;
}

.videoupload {
  margin-bottom: 12px;
}

.formcreatable .css-13cymwt-control {
  background: rgba(250, 250, 250, 1);
  border: 1px solid transparent;
  height: 45px;
  position: relative;
  font-size: 14px;
  width: 100%;
  -webkit-border-radius: 6px;
  height: 45px;
  border-radius: 4px;
  /* margin-bottom: 20px; */
  padding-left: 10px;
  font-weight: 400;
  line-height: 19px;
  color: #646464 !important;
  margin-top: 8px;
}

.css-13cymwt-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  /* -webkit-flex-wrap: wrap; */
  /* -ms-flex-wrap: wrap; */
  /* flex-wrap: wrap; */
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: transparent !important;
  border: none !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: rgba(250, 250, 250, 1) !important;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
}
.css-1bw0nnu-MuiStep-root {
  padding-left: 8px;
  padding-right: 0px !important;
}

.css-m5vj9m-MuiStepper-root {
  /* display: -webkit-box; */
  /* display: -webkit-flex; */
  display: -ms-flexbox;
  display: block !important;
  /* -webkit-flex-direction: row; */
  -ms-flex-direction: row;
  flex-direction: row;
  /* -webkit-align-items: center; */
  /* -webkit-box-align: center; */
  -ms-flex-align: center;
  align-items: center;
}
