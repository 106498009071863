/* signup start */

.inputt {
  font-size: 14px;
  height: 50px;
  width: 100%;
}

.signup .inputnumberr {
  font-size: 14px;
  height: 50px;
}

.signup .inputnumberrtest {
  font-size: 16px;
  height: 50px;
}

.signupp {
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 54px;
  align-items: center;
  text-align: center;
  /* color: #000000; */
}

.box-signupp {
  /* background: #ffff; */
  width: 550px;
  border-radius: 6px;
}

.signup {
  font-style: normal;
  font-size: 30px;
  font-weight: 600;
  width: 100% !important;
}

.eyepasswordsignup {
  position: absolute;
  padding: 25px;
  right: 28%;
  color: #fab818;
  cursor: pointer;
  font-size: 20px !important;
}

.eyepasswordsignup :hover {
  right: 33%;
  color: #fab818;
  font-size: 20px !important;
}

.Read_Policy {
  margin-right: 15%;
  font-size: 1vw;
}

.Read_Policy label {
  margin-left: 1%;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.signup i.rounded {
  font-size: 30px;
  width: 40px;
  margin: 5px;
  color: #929191;
  height: 43px;
}

.signup i.rounded :hover {
  font-size: 30px;
  width: 40px;
  margin: 5px;
  color: #fab818;
  height: 43px;
}

.rounded {
  border: 1px solid;
  border-radius: 50% !important;
  padding: 1%;
}

.accountsign {
  color: #676666;
  font-family: "Poppins";
}

.accountsignn span {
  /* color: #000000; */
  text-decoration: underline;
  font-weight: 900;
}

h6.social-logiin {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.inputttest {
  background: #ffff;
  color: black !important;
  font-family: "Poppins";
}

.ccode {
  background: white;
  border: 1px solid #7a7777;
  height: 50px;
}

.ccodee {
  width: 77%;
}

.ccodee .PhoneInputInput {
  height: 50px;
  padding: 2%;
}

.ccodee .PhoneInputCountry {
  height: 50px;
  border: 1px solid #7e7e7e;
  padding: 5%;
}

.ccodee.PhoneInput {
  margin: 0 auto;
}

.codenumber {
  width: 61%;
}

.PhoneInputCountry {
  color: black;
}

.siguperrorr {
  color: red;
  /* margin-left: 13%; */
  margin-top: 1%;
  display: flex;
  font-family: "Poppins" !important;
  font-size: 13px;
}

.form-select1 {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-family: "Poppins-Light";
  font-weight: 400;
  line-height: 1.5;
  color: black;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  /* border-radius: .25rem; */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* signup end */

/* model content  */

.agree-model {
  background-color: #000000;
}

.button-close {
  background-color: #fab818;
  color: #ffff;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  font-size: 18px;
  font-family: "Poppins";
}

.button-close :hover {
  background-color: #fab818 !important;
  color: #ffff !important;
}

.close {
  background: none;
  color: white;
  border: none;
}

/* model content  */

.signin-logo-images {
  width: 200px !important;
  height: 40px !important;
}

.custom-country .stdropdown-container {
  border-top: none;
  border-left: none;
  border-right: none;
  margin-bottom: 10px;
  border-radius: 0px;
}

.custom-country .stdropdown-container:focus-visible,
.country-input:focus-visible {
  outline: none;
}

.stdropdown-item {
  font-size: 15px;
  font-family: 'Poppins';
}

.country-input {
  font-size: 15px;
  padding: 10px 0px !important;
  margin-bottom: 12px;
}

/* Mobile view  */

/* Custom, iPhone Retina */
@media only screen and (min-width: 200px) {
  .eyepasswordsignupp {
    position: absolute;
    padding: 30px;
    right: 4%;
    font-size: 20px !important;
    color: black;
  }
}

@media only screen and (min-width: 320px) {
  .eyepasswordsignupp {
    position: absolute;
    padding: 30px;
    right: 4%;
    font-size: 20px !important;
    color: black;
  }

  .Read_Policy {
    margin-right: 6%;
    font-size: 1vw;
  }

  .Read_Policy label {
    font-size: 4vw;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .eyepasswordsignupp {
    position: absolute;
    padding: 30px;
    right: 2%;
    font-size: 20px !important;
    color: black;
  }

  .Read_Policy {
    margin-right: 20%;
    font-size: 1vw;
  }

  .Read_Policy label {
    font-size: 3vw;
  }
}

@media only screen and (min-width: 600px) {
  .eyepasswordsignupp {
    position: absolute;
    padding: 30px;
    right: 12%;
    font-size: 20px !important;
  }

  .Read_Policy {
    margin-right: 10%;
    font-size: 1vw;
  }

  .Read_Policy label {
    font-size: 3vw;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .eyepasswordsignupp {
    position: absolute;
    padding: 30px;
    right: 20%;
    font-size: 20px !important;
    color: black;
  }

  .Read_Policy {
    margin-right: 20%;
    font-size: 1vw;
  }

  .Read_Policy label {
    font-size: 2vw;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .eyepasswordsignupp {
    position: absolute;
    padding: 30px;
    right: 29%;
    font-size: 20px !important;
    color: black;
  }

  .Read_Policy {
    margin-right: 9%;
    font-size: 1vw;
  }

  .Read_Policy label {
    font-size: 2vw;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .eyepasswordsignupp {
    position: absolute;
    padding: 30px;
    right: 29%;
    font-size: 20px !important;
    color: #000000;
  }

  .Read_Policy {
    margin-right: 19%;
    font-size: 1vw;
  }

  .Read_Policy label {
    font-size: 1.5vw;
  }
}

@media (min-width: 320px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 25px;
    right: 0;
    color: #fab818;
    font-size: 20px !important;
  }
}

@media (min-width: 481px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 25px;
    right: 6%;
    color: #fab818;
    font-size: 20px !important;
  }
}

@media (min-width: 641px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 25px;
    right: 14%;
    color: #fab818;
    font-size: 20px !important;
  }
}

@media (min-width: 961px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 25px;
    right: 22%;
    color: #fab818;
    font-size: 20px !important;
  }
}

@media (min-width: 1025px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 25px;
    right: 24%;
    color: #fab818;
    font-size: 20px !important;
  }
}

@media (min-width: 1281px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 25px;
    right: 28%;
    color: #fab818;
    font-size: 20px !important;
  }
}

@media screen and (min-width: 1200px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 25px;
    right: 28%;
    color: #fab818;
    font-size: 20px !important;
  }
}

@media screen and (min-width: 1400px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 25px;
    right: 31%;
    font-size: 20px !important;
  }

  .Read_Policy {
    margin-right: 0px;
    font-size: 1vw;
  }

  .Read_Policy label {
    font-size: 1.5vw;
  }
}

@media screen and (min-width: 1600px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 25px;
    right: 33%;
    font-size: 20px !important;
  }

  .Read_Policy {
    margin-right: 21%;
    font-size: 1vw;
  }

  .Read_Policy label {
    font-size: 1vw;
  }
}

@media screen and (min-width: 1900px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 25px;
    right: 35%;
    font-size: 20px !important;
  }

  .Read_Policy {
    margin-right: 13%;
    font-size: 1vw;
  }

  .Read_Policy label {
    font-size: 1vw;
  }
}

@media screen and (min-width: 2100px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 25px;
    right: 38%;
    font-size: 20px !important;
  }
}

@media screen and (min-width: 2400px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 25px;
    right: 39%;
    font-size: 20px !important;
  }
}

@media screen and (min-width: 2500px) {
  .eyepasswordsignup {
    position: absolute;
    padding: 25px;
    right: 40%;
    font-size: 20px !important;
  }
}

@media screen and (min-width: 3840px) {}