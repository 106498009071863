.homePageNavbar {
  z-index: 999;
  height: 56px;
}

.homePageNavbar.scrolled {
  animation: toTopFromTop 0.6s forwards;
  /* box-shadow: 0px 0px 7px 0px #cfcfcf9e !important; */
}

.homeNavImage {
  width: 16px !important ;
  height: 16px !important;
  object-fit: contain;
  margin-bottom: 2px;
}

.avatarUrlinitialsname img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.homeNavImageArrow {
  width: 20px;
  height: 20px;
  object-fit: contain;
  transform: rotate(90deg);
}

.toggleButtonTheme {
  width: 50px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid #877171;
  padding: 3px;
}

.toggleCircleButton {
  width: 20px;
  height: 20px;
  top: 1px;
  left: 3px;
  transition: left 0.3s linear;
  position: absolute;
  border-radius: 50%;
  /* background-color: #877171 !important; */
}

@keyframes toTopFromTop {
  0% {
    top: -80px;
  }

  100% {
    top: 0;
  }
}


.homeNavLink {
  font-family: "Poppins";
  font-size: 15px;
  line-height: 24px;
  text-align: left;
  font-weight: 500;
  /* color: rgb(233, 233, 233); */
}

.homeNavLink.active {
  text-decoration: none;
  color: #fff;

}

.homeNavLink:hover {
  text-decoration: none;
}

.homeNavList:hover .homeNavDropDown {
  display: block !important;
}

/* 
.homeNavList::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  background: #87c4ff;
  transition: all 0.5s;
  height: 2px;
} */

/* .homeNavList:hover::after {
  width: 100%;
} */

.homeNavDropDown {
  position: absolute;
  top: 50px;
  /* left: 0px; */
  z-index: 2;
  padding-left: 5px;
  /* background: #021e5e; */
  width: 150px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.accessButton {
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  padding: 7px 20px;
  width: max-content;
}


.userText {
  font-family: "Poppins";
  font-size: 13px;
  text-align: left;
  font-weight: 600;
  margin-left: 6px;
  min-width: 55px;
}

.userText:hover {
  text-decoration: none;
  /* color: #fff; */
}

.headerSubLink {
  font-family: "Poppins";
  font-size: 14px;
  text-align: left;
  font-weight: 500;
  line-height: 25px;
}

.userContainer:hover .userListBox {
  display: block !important;
}

.userListBox {
  position: absolute;
  top: 25px;
  right: 0;
  padding-top: 18px;
  z-index: 2;
  width: 200px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.shadowHeaderMenu {
  box-shadow: 0px 10px 25px 0px #000000;
}

.accessButton:hover {
  text-decoration: none;
}

.portalBlockMobile {
  display: none !important;
}

.homeNavList {
  position: relative;
}

.homeNavListLoader {
  width: 135px;
}

.homeNavListLoader .skeletonStatic {
  border-radius: 5px;
}

.languageBtn:focus,
.languageBtn:focus-visible {
  outline: 0 !important;
}

.languageBtn.show {
  border-color: transparent !important;
}

.languageDrop .dropdown-item:hover,
.languageDrop .dropdown-item:focus {
  opacity: 1 !important;
}

.signin-popup-container {
  z-index: 9999 !important;
}

.userAvatarLogoIcon {
  width: 41px !important;
  height: 41px !important;
}

@media screen and (max-width: 768px) {
  .homeNavbarBrand {
    width: 180px !important;
    height: 38px !important;
  }

  .homePageNavbar .dropdown-menu {
    top: 10px !important;
  }
}

@media screen and (max-width: 1200px) {
  .homeNavbarMobileFixed {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
    transition: left 0.4s ease-out;
    z-index: 10;
    overflow: overlay;
  }

  /* .homeNavList:nth-child(1)::after {
    display: none;
  } */

  .homeNavList {
    width: 100%;
  }

  .homeNavbarMobileFixed.active {
    left: 0;
  }

  .toggleIconHome {
    display: block !important;
    width: 30px;
    height: 30px;
  }

  .homeNavSlide {
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: overlay;
    height: auto;
  }

  .closeIcons {
    display: flex !important;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    padding: 10px 15px;
  }



  .portalBlock {
    display: none !important;
  }

  .portalBlockMobile {
    display: flex !important;
    justify-content: flex-start;
    width: 100%;
  }

  .userListBox {
    right: 0;
    left: auto;
  }

  .homeNavLink {
    font-size: 18px;
    line-height: 15px;
  }

  .homeNavDropDown {
    position: relative;
    top: auto;
    left: auto;
    background-color: transparent !important;
  }

  .homeNavDropDown .homeNavLink {
    padding: 9px 0px;

  }
}

/* App.css */

.profile-image {
  font-size: 18px;
  width: 41px;
  height: 41px;
}

.searchInputHeader {
  position: absolute;
  top: 56px;
  right: 0;
  width: 0;
  font-size: 18px;
  opacity: 0;
}

.searchBox input::placeholder {
  font-weight: 400;
}

.searchBox .icon-search {
  width: 100%;
  height: 100%;
  padding: 9px 10px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.5s;
}

.searchBox:hover .searchInputHeader {
  width: 240px;
  transition: all 0.5s linear;
  opacity: 1;
}

.searchInputHeader:focus {
  border: none;
  outline: none;
}



/* .dropdown-item.disabled, .dropdown-item:disabled {
  color: red;
  pointer-events: none;
  background-color: transparent;
} */