.category-card button {
  border-radius: 50px;
  font-size: 16px;
  transition: none;
  background-color: none !important;
}
.inactive-category {
  transition: none;
}
.inactive-category:hover {
  border: 2px solid #0056b3;
}



