.sideBarText {
    text-transform: capitalize;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    padding: 6px;
    text-align: center;
    position: relative;
    width: 100%;
}

.sideBarText::before {
    content: "";
    width: 5px;
    height: 100%;
    position: absolute;
    background-color: #38b2ac;
    left: 0;
}