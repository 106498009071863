.hovertext {
  position: relative;
}

.hovertext:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 80px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 2px 0;
  transition: opacity 1s ease-in-out;
  position: absolute;
  z-index: 1;
  right: 10%;
  bottom: 100%;
}

.hovertext:hover:before {
  opacity: 1;
  visibility: visible;
}

.hovertextedit {
  position: relative;
}

.hovertextedit:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 90px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 2px 0;
  transition: opacity 1s ease-in-out;
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 100%;
}

.hovertextedit:hover:before {
  opacity: 1;
  visibility: visible;
}

.marginbb {
  margin-top: 1rem;
  margin-bottom: 5.2rem;
}

.expo-rt .dropdown-toggle::after {
  border-top: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  border-left: 0 !important;
}

.all-episode-stream .dropdown-toggle::after {
  display: none;
}

.all-episode-stream .profile {
  width: 65px;
  height: 65px;
  object-fit: contain;
}


.all-episode-stream .bootstrapTable table>tbody>tr>td {
  white-space: normal;
  word-break: break-all;
  border: none;
  font-size: 16px;
  padding: 10px;
  width: 130px;
}

.allepisodechannel .modal-dialog {
  top: 20%;
}

