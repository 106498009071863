.moreLanguageBox {
    width: 150px;
    top: 10px;
    left: 52px;
}

.moreLanguageBox>li {
    font-size: 15px;
    line-height: 20px;
    font-weight: bold;
}

.listSub:hover + .moreLanguageBox {
    display: block !important;
}