/* header start */
/* Dropdown menus */
.contentHeader .dropdown-menu {
  border-radius: 4px;
  padding: 10px 0;
  -webkit-animation-name: dropdown-animate;
  animation-name: dropdown-animate;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  border: 0;
  box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
}

.contentHeader .dropdown-menu .dropdown-header,
.contentHeader .dropdown-menu .dropdown-footer {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
}

.contentHeader .dropdown-menu .dropdown-footer a {
  color: #444444;
  text-decoration: underline;
}

.contentHeader .dropdown-menu .dropdown-footer a:hover {
  text-decoration: none;
}

.contentHeader .dropdown-menu .dropdown-divider {
  color: #a5c5fe;
  margin: 0;
}

.contentHeader .dropdown-menu .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.contentHeader .dropdown-menu .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.contentHeader .dropdown-menu .dropdown-item:hover {
  background-color: #f6f9ff;
}

@media (min-width: 768px) {
 .contentHeader .dropdown-menu-arrow::before {
    content: "";
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 20px;
    transform: rotate(45deg);
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
  }
}

@-webkit-keyframes dropdown-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

/* Light Backgrounds */
.bg-primary-light {
  background-color: #cfe2ff;
  border-color: #cfe2ff;
}

.bg-secondary-light {
  background-color: #e2e3e5;
  border-color: #e2e3e5;
}

.bg-success-light {
  background-color: #d1e7dd;
  border-color: #d1e7dd;
}

.bg-danger-light {
  background-color: #f8d7da;
  border-color: #f8d7da;
}

.bg-warning-light {
  background-color: #fff3cd;
  border-color: #fff3cd;
}

.bg-info-light {
  background-color: #cff4fc;
  border-color: #cff4fc;
}

.bg-dark-light {
  background-color: #d3d3d4;
  border-color: #d3d3d4;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.logo {
  line-height: 1;
}

@media (min-width: 1200px) {
  .logo {
    width: 280px;
  }
}

.logo img {
  max-height: 26px;
  margin: 10px;
}

.logo span {
  font-size: 26px;
  font-weight: 700;
  color: #012970;
  font-family: "Nunito", sans-serif;
}

.over-all-content .header .toggle-sidebar-btn {
  font-size: 32px;
  padding-left: 10px;
  cursor: pointer;
  color: #012970;
}

.username {
  font-size: 16px !important;
  color: #000000;
}

.username:hover {
  font-size: 16px;
  color: #000000;
}

.nav-portal {
  /* padding-left: 120px !important; */
}

@media (max-width: 1199px) {
  .over-all-content .header .search-bar {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    padding: 20px;
    box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
    background: white;
    z-index: 9999;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
  }

  .over-all-content .header .search-bar-show {
    top: 60px;
    visibility: visible;
    opacity: 1;
  }
}

.over-all-content .header .search-form {
  width: 100%;
}

.over-all-content .header .search-form input {
  border: 0;
  font-size: 14px;
  color: #012970;
  border: 1px solid rgba(1, 41, 112, 0.2);
  padding: 7px 38px 7px 8px;
  border-radius: 3px;
  transition: 0.3s;
  width: 100%;
}

.over-all-content .header .search-form input:focus,
.over-all-content .header .search-form input:hover {
  outline: none;
  box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
  border: 1px solid rgba(1, 41, 112, 0.3);
}

.over-all-content .header .search-form button {
  border: 0;
  padding: 0;
  margin-left: -30px;
  background: none;
}

.over-all-content .header .search-form button i {
  color: #012970;
}

/*--------------------------------------------------------------
# Header Nav
--------------------------------------------------------------*/
.over-all-content .header-nav ul {
  list-style: none;
}

.over-all-content .header-nav > ul {
  margin: 0;
  padding: 0;
}

.over-all-content .header-nav .nav-icon {
  font-size: 22px;
  color: #012970;
  position: relative;
}

.over-all-content .header-nav .nav-profile {
  color: #012970;
}

.over-all-content .header-nav .nav-profile img {
  max-height: 36px;
}

.over-all-content .header-nav .nav-profile span {
  font-size: 16px;
  font-weight: 600;
}

.over-all-content .header-nav .badge-number {
  position: absolute;
  inset: 0px 5px auto auto;
  font-weight: normal;
  font-size: 12px;
  padding: 2px 5px;
}

.over-all-content .header-nav .notifications {
  inset: 8px -231px auto auto !important;
}

.over-all-content .header-nav .notifications .notification-item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  transition: 0.3s;
}

.over-all-content .header-nav .notifications .notification-item i {
  margin: 0 20px 0 10px;
  font-size: 24px;
}

.over-all-content .header-nav .notifications .notification-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.over-all-content .header-nav .notifications .notification-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.over-all-content .header-nav .notifications .notification-item:hover {
  background-color: #f6f9ff;
}

.over-all-content .header-nav .messages {
  inset: 8px -215px auto auto !important;
}

.over-all-content .header-nav .messages .message-item {
  padding: 15px 10px;
  transition: 0.3s;
}

.over-all-content .header-nav .messages .message-item a {
  display: flex;
}

.over-all-content .header-nav .messages .message-item img {
  margin: 0 20px 0 10px;
  max-height: 40px;
}

.over-all-content .header-nav .messages .message-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #444444;
}

.over-all-content .header-nav .messages .message-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.over-all-content .header-nav .messages .message-item:hover {
  background-color: #f6f9ff;
}

.over-all-content .header-nav .profile {
  min-width: 240px;
  padding-bottom: 0;
  top: 8px !important;
}

.over-all-content .header-nav .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: #444444;
}

.over-all-content .header-nav .profile .dropdown-header span {
  font-size: 14px;
}

.over-all-content .header-nav .profile .dropdown-item {
  font-size: 14px;
  padding: 8px 10px;
  transition: 0.3s;
}

.over-all-content .header-nav .profile .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.over-all-content .header-nav .profile .dropdown-item:hover {
  background-color: #f6f9ff;
}

.liveAlert {
  position: fixed;
  right: 0;
  z-index: 9999;
  top: 17px;
  right: 5px;
}
/* header end*/
