.signin-logo-image {
    width: 170px;
    height: 35px;
    object-fit: contain;
}

.signinText {
    font-family: 'Poppins';
    font-size: 25px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
}

.signin-input {
    height: 50px;
    width: 100%;
    font-size: 16px;
    background-color: transparent;
}

.popup-active.signUpActive {
    overflow-y: auto;
    height: calc(100vh - 80px);
}

.popup-active.login {
    height: 480px;
    overflow-y: auto;
}

.signInMenu .eyeButton {
    position: absolute;
    top: 4px;
    right: 4px;
    border-radius: 5px;
}

.signin-input:-webkit-autofill .eyeIcons,
/* .signin-input:-webkit-autofill:hover .eyeIcons, */
.signin-input:-webkit-autofill:focus .eyeIcons,
.signin-input:-webkit-autofill:active .eyeIcons {
    color: #000 !important;
}

.signin-input::placeholder {
    color: #ccc;
}

.signin-input:focus,
.signin-input:active,
.signin-input:hover {
    outline: none !important;
}

.signinLabelText {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
}

.signinbutton {
    font-size: 22px;
    font-weight: 800;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    height: 50px;

}

.forgotbutton {
    font-size: 16px;
    font-weight: 400;
    /* line-height: 38px; */
    letter-spacing: 0em;
    text-align: center;
}

.signin-error {
    font-size: 13px;
    font-family: 'Poppins';
    bottom: -10px;
}

.closeIconSignin {
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    opacity: 1;
}

.login-error {
    font-size: 14px;

}

.mobileWidth:nth-child(1) {
    width: 35%;
}

.mobileWidth:nth-child(2) {
    width: 60%;
}

.checkboxHeight {
    height: 60px;
}

.ownButtonClass .selected-flag {
    background-color: transparent !important;

}

.ownInputFlag:focus {
    box-shadow: none !important;
}

.ownInputDropDown .country:hover,
.ownInputDropDown .country.highlight {
    background-color: transparent !important;
}


.ownButtonClass .react-tel-input .flag {
    transform: scale(1.5);
}

@keyframes fadeIn {
    0% {
        background-color: rgba(0, 0, 0, 0.5);
    }

    100% {
        background-color: rgba(0, 0, 0, 0.7);
    }

}

.code {
    width: 2px;
    height: 47px;
    background-color: #ccc;
    border-radius: 5px;
    display: block;
}

.eyeButton {
    top: 5px;
    right: 5px;
    position: absolute;
}

@media screen and (max-width:527px) {
    .closeIconSignin {
        top: 10px;
        right: 10px;
    }

    .signinText {
        font-size: 15px;
        line-height: 17px;
    }

    .signinLabelText {
        font-size: 13px;
        line-height: 21px;
    }

    .mobileWidth {
        width: 100% !important;
    }

    .signinbutton {
        font-size: 18px;
        width: 195px !important;
    }

    .signin-input::placeholder {
        font-size: 14px;
    }

}