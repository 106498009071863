/* Banner image styles */
.bannerWrapper,
.bannerContainer {
    position: relative;
    width: 100%;
    height: 100%;
}

/* Apply gradient overlay on the image */
.banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease-in-out;
}

.banner-image.visible {
    opacity: 1;
}

.banner-image.hidden {
    opacity: 0;
}

/* Gradient overlay on image */
.banner-image::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0,0,0,0.5) 50%, transparent);
    pointer-events: none;
}

/* Ensure BannerOverlay elements appear above the image */
.bannerWrapper .bannerOverlay,
.bannerContainer .bannerOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
