/* editmultiple start */


.editmultiple input {
  font-size: 18px;
  height: 50px;
}

.editmultiple h1 {
  /* color: #ffff; */
  font-style: normal;
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 54px;
  align-items: center;
  text-align: center;
  font-family: 'Poppins';
  /* color: #000000; */
}

.box-editmultiple {

  width: 400px;
  border-radius: 6px;
  padding: 24px;

}

.editmultiple button.button {
  background: #1443c5;
  color: #ffff !important;
  font-family: 'Poppins';
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
}

.editmultiple button.button :hover {
  background: #1f346f;
  color: #ffff !important;
  font-family: 'Inter';
  font-style: normal;
  font-size: 24px;
  font-weight: 600;
}


.box-signin a {
  text-decoration: none;

}

.forgot {
  text-align: start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-decoration: none;
  color: #676666;
  /* margin-right: 13%; */
  margin-left: 13%;
}

.editmultiple i.rounded {
  font-size: 30px;
  width: 40px;
  margin: 5px;
  color: #929191;
  height: 43px;
}



.editmultiple i.rounded :hover {
  font-size: 30px;
  width: 40px;
  margin: 5px;
  color: #FAB818;
  height: 43px;
}

.rounded {
  border: 1px solid;
  border-radius: 50% !important;
  padding: 1%;
}

.accountmultiple {
  color: #676666;
}

.accountmultiple span {
  color: #FAB818;
  text-decoration: underline;
}

h6.social-login {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #676666;
}


.kid {
  font-size: 15px;
  font-weight: 300;
}

.avatar_img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.kid_profile {
  padding-top: 20px;
  padding-bottom: 10px;
}

/* editmultiple end */