.accountTitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}

.field-group {
  width: 100%;
  position: relative;
  padding-top: 10px;
  font-family: "Poppins";
  font-weight: 300;
}

.account-label {
  font-size: 17px;
  font-weight: 200;
}

.account-input {
  font-size: 15px;
  font-weight: 400;
}



.account_main_page label {
  /* background-color: #fff; */
  font-family: "Poppins";
}

.account_main_page table.table {
  color: #fff;
}

.account_main_page button.nav-link {
  text-align: start;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 300;
}

.labelText {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 300;
}

.labelText1 {
  font-size: 16px;
  font-weight: 400;
}

.account_main_page .nav-link {
  color: #fff;
}

.account_main_page .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: transparent;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 300;
}

.accountNameText {
  font-size: 32px;
  font-family: "Poppins";
  font-weight: 500;
}

.accountDetailsText {
  font-family: "Poppins";
  font-weight: 300;
}

.accountDetails {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 16px;
}

.accountupdate {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 18px;
  color: #578cea;
}

.recentView.table {
  --bs-table-bg: transparent !important;
  --bs-table-color: white !important;
  border-color: none !important;
}

.displayProfile {
  width: 40px;
  height: 40px;
}

/* Account */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profileBtn {}

@media (max-width: 320px) {
  .header-container {
    flex-direction: column;
    text-align: center;
  }

  .header-container h2,
  .header-container a {
    margin: 10px 0;
  }
}

.field-group label {
  display: block;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profileImageUpdate {
  max-width: 80px;
  height: 80px !important;
  width: 80px;
}

.profileInput {
  top: 0;
  opacity: 0;
  z-index: 3;
  left: 0;
}

.themeTwoDeviceAvatar {
  width: 30px;
  height: 30px !important;
}

.themeTwoProfileAvatar {
  width: 50px;
  height: 50px !important;
}

.profileMultiImageUpdate {
  width: 150px;
  height: 150px !important;
}

.account input:is(:focus, :active:focus-visible) {
  outline: none;
}

@media (max-width: 320px) {
  .field-group label {
    font-size: 14px;
  }


}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}