.progress-filled {
    height: 100%;
    border-radius: 25px;
    position: relative;
    width: 0%;
    transition: width 0.1s ease-out;
}

.track-info {
    display: flex;
    justify-content: space-around;
    padding-top: 5px;
}

.progress-filled-circle {
    left: 0;
    transition: left 0.1s ease-out;
}