.viewAllCardTwo {
  aspect-ratio: 9/13;
  width: 95%;
  margin: 0 auto 10px;
}

.hoverCardContainer {
  position: absolute;
  min-height: 328px;
  width: 250px;
  top: 5px;
  left: 0;
  overflow: hidden;
  border-radius: 5px;
  z-index: 1;
  display: none;
  flex-direction: column;
  justify-content: space-between;
}

@keyframes hoverCard {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }

}

.viewAllCardTwo:hover .hoverCardContainer {
  display: flex;
  z-index: 10;
}


.viewAllCardTwo.last-child .hoverCardContainer {
  right: 20px;
  left: auto;
}

.arrowButton,
.mapButton {
  cursor: pointer;
}
.mapButton{
  width: 45px;
  height: 38px;
}

.arrowButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.viewCardImage {
  width: 100%;
  height: 165px !important;
  object-fit: cover;
}

.viewAllCardTwo-test-test {
  height: 250px !important;
  margin: 5px;
}


.viewAllDesc {
  font-family: "Poppins";
  font-size: 11px;
  line-height: 15px;
  font-weight: 400;
  color: #818181;
  min-height: 53px;
}

.viewAllCardHeading {
  font-family: "Poppins";
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  font-weight: 600;
  margin-bottom: 0px;
}

.homeListImage-catego {
  width: 100%;
  height: 170px;
  object-fit: cover;
  position: relative;
}

.homeListImage-catego::after {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  height: 35%;
  background: linear-gradient(to top, #16181f, transparent);
}

/* .viewAllCardContentTwo::before {
  position: absolute;
  content: "";
  background-image: linear-gradient(to bottom, transparent, #16181f, #16181f);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: 6px;
  overflow: hidden;
} */

/* .viewAllCardContentTwo {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 328px;
  width: 250px;
  visibility: hidden;
  z-index: 23;
} */

.viewAllCardTwo:hover>.viewImage {
  filter: blur(2px);
}

.fontforcircletest svg {
  font-size: 15px;
}

.viewAllPagination {
  display: flex;
  margin: 20px 0px;
}

.viewAllPagination button {
  color: #fff;
  padding: 8px;
  margin: 0 4px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #2c3e50;
}

.viewAllPagination button:hover {
  background-color: #2c3e50;
}

.viewAllPagination button.active {
  background-color: #2c3e50;
}

.viewAllTitle {
  font-size: 25px;
  font-family: "Poppins";
  text-transform: capitalize;
}

.viewAllCardSize {
  width: 204px;
  height: 303px;
}

.viewListContainer {
  min-height: 457px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewListContainerNot {
  min-height: 457px;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(1, calc(100% / 1));
}

.viewallCartText {
  width: 14px;
  margin-bottom: 5px;
}

.costText {
  position: absolute;
  top: 0;
  left: 0;
  width: 52px;
  font-weight: 500;
  height: 35px;
  border-radius: 3px;
  border-top: none;
  border-left: none;
  border-bottom-right-radius: 15px;
  font-family: "Poppins";
  z-index: 1;
}

.viewallCartHeading {
  font-size: 14px;
  font-family: "Poppins";
  line-height: 15px;
  font-weight: 600;
}

.viewallCartPara {
  font-size: 13px;
  font-family: "Poppins";
  line-height: 15px;
  font-weight: 600;
}

.viewallCartPlayBtn {
  border-radius: 7px;
  padding: 10px 10px;
  position: relative;
}

.viewAllCardTwo:nth-child(2n-1):hover .hoverCardContainer {
  left: 38px;
}

.viewAllCardTwo:nth-child(2n+0):hover .hoverCardContainer {
  right: 38px;
  left: auto;
}


@media screen and (max-width:425px) {
  .hoverCardContainer {
    width: 200px;
  }
}

@media screen and (max-width: 768px) {
  .viewAllTitle {
    font-size: 20px;
    line-height: 20px;
  }
}

.viewAllCardTwo:hover .hoverCardContainer {
  animation: hoverCard 0.4s ease-in-out forwards;
}

@media screen and (min-width:425px) {
  .viewAllCardTwo:hover .hoverCardContainer {
    animation: hoverCard 0.4s ease-in-out forwards;
  }
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(2, calc(100% / 2));
}

@media screen and (min-width: 425px) {
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(2, calc(100% / 2));
  }

  .viewAllCardTwo:nth-child(2n+0):hover .hoverCardContainer {
    right: 22px;
    left: auto;
  }

  .viewAllCardTwo:nth-child(2n-1):hover .hoverCardContainer {
    left: 23px;
  }
}

@media screen and (min-width: 425px) and (max-width:768px) {
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
  }

  .viewAllCardTwo:nth-child(3n+0):hover .hoverCardContainer {
    right: 45px;
    left: auto;
  }

  .viewAllCardTwo:nth-child(3n-2):hover .hoverCardContainer {
    left: 45px;
  }
}

@media screen and (min-width: 768px) and (max-width:992px) {
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(4, calc(100% / 4));
  }

  .viewAllCardTwo:nth-child(4n+0):hover .hoverCardContainer {
    right: 45px;
    left: auto;
  }

  .viewAllCardTwo:nth-child(4n-3):hover .hoverCardContainer {
    left: 45px;
  }
}

@media screen and (min-width: 992px) and (max-width:1200px) {
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(5, calc(100% / 5));
  }

  .viewAllCardTwo:nth-child(5n+0):hover .hoverCardContainer {
    right: 45px;
    left: auto;
  }

  .viewAllCardTwo:nth-child(5n-4):hover .hoverCardContainer {
    left: 45px;
  }
}

@media screen and (min-width: 1200px) and (max-width:1400px) {
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(6, calc(100% / 6));
  }

  .viewAllCardTwo:nth-child(6n+0):hover .hoverCardContainer {
    right: 45px;
    left: auto;
  }

  .viewAllCardTwo:nth-child(6n-5):hover .hoverCardContainer {
    left: 45px;
  }
}

@media screen and (min-width: 1400px) {
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(7, calc(100% / 7));
  }

  .viewAllCardTwo:nth-child(7n+0):hover .hoverCardContainer {
    right: 45px;
    left: auto;
  }

  .viewAllCardTwo:nth-child(7n-6):hover .hoverCardContainer {
    left: 45px;
  }
}