signin start

.signin input {
  font-size: 18px;
  height: 50px;
}

.signinn  {
  /* color: #ffff; */
  font-style: normal;
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 54px;
  align-items: center;
  text-align: center;
  font-family: 'Poppins';
  /* color: #000000; */
}
.signbutton{
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 600;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.box-signinn {
  /* background: #ffff; */
  width: 550px;
  /* border-radius: 6px; */
  border-radius: 0px;
    padding: px;
    /* background: rgba(58, 57, 57, 0.75);
    box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5); */
}

.signin button.button {
  background: #111727;
  color: #ffff !important;
  font-family: 'Poppins';
  font-style: normal;
  font-size: 30px;
  font-weight: 600;
}

.signin button.button :hover {
  background: #111727;
  color: #ffff !important;
  font-family: 'Poppins';
  font-style: normal;
  font-size: 30px;
  font-weight: 600;
}

.eyepasswordsignin {
  position: absolute;
  padding: 35px;
  right: 31%;
  color: #d0cfcf;
  font-size: 18px !important;
}
.eyepasswordsignin :hover {
  right: 31%;
  color: #fab818;
  font-size: 18px !important;
}
.box-signin a {
  text-decoration: none;
}
.signin .forgot {
  text-align: end !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-decoration: none;
  color: blue;
}

.signin i.rounded {
  font-size: 20px;
  width: 33px;
  margin: 5px;
  /* color: #929191; */
  height: 33px;
}

.sharing_social-media{
  font-size: 25px !important;
  border-radius: 50px;
  border: 1px solid;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding: 5px;
  padding-top: 0;
}
.signin i.rounded:hover {
  font-size: 20px;
  width: 33px;
  margin: 5px;
  color: #fab818;
  height: 33px;
}

.rounded {
  border: 1px solid;
  border-radius: 50% !important;
  padding: 1%;
}

.accountsignn {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.accountsign span {
  color: #fab818;
  text-decoration: underline;
}

.social-loginn {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 22px;
 
}


.siginerrorr {
  margin-left: 3%;
  display: flex;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 200;
}


/* signin end */

/* Mobile view  */

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
  .eyepasswordsignin {
    position: absolute;
    padding: 35px;
    right: 12%;
    font-size: 18px !important;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .eyepasswordsignin {
    position: absolute;
    padding: 35px;
    right: 22%;
    font-size: 18px !important;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .eyepasswordsignin {
    position: absolute;
    padding: 35px;
    right: 18%;
    font-size: 18px !important;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .eyepasswordsignin {
    position: absolute;
    padding: 35px;
    right: 26%;
    font-size: 18px !important;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .eyepasswordsignin {
    position: absolute;
    padding: 35px;
    right: 31%;
    font-size: 18px !important;
  }
}
@media screen and (min-width: 1400px) {
  .eyepasswordsignin {
    position: absolute;
    padding: 35px;
    right: 33%;
    font-size: 18px !important;
  }
}
@media screen and (min-width: 1600px) {
  .eyepasswordsignin {
    position: absolute;
    padding: 35px;
    right: 37%;
    font-size: 18px !important;
  }
}
@media screen and (min-width: 1900px) {
  .eyepasswordsignin {
    position: absolute;
    padding: 35px;
    right: 40%;
    font-size: 18px !important;
  }
}
