@import url("./Styles/rtl-support.css");
@import url("./Styles/Swiper.css");
@import url("./Styles/response.css");
@import url("./Styles/BoostrapTable.css");
@import url("./Styles/AuthStyle.css");


body {
  font-family: "Poppins", serif !important;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #424242;
}

:is(button, p, input, label, span, select) {
  font-family: "Poppins";
}

:is(h1, h2, h3, h4, h5, h6) {
  font-family: "Poppins" !important;
}

.heightBody {
  height: 100vh;
}

.heightForChild {
  padding-top: 56px;
}

.width-max {
  width: max-content;
}

.input-label {
  display: block;
  position: absolute;
  top: 0;
  font-weight: 300;
  font-size: 20px;
  font-family: "Poppins";
}

.loaderAll {
  height: 100vh;
  width: 100%;
}

.loaderAll>img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.homePageCardHeading {
  font-family: "Poppins";
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}

.homePageCardHeading::after {
  display: none;
}

.homeListImage {
  width: 100%;
  height: 180px;
  object-fit: cover;
  position: relative;
}

.homeListImage::after {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  height: 70%;
}

.homeListImage.active::after {
  height: 40%;
}

.homeLiveImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 3px;
}

.homeListContent {
  z-index: 1;
}

.homeListButton {
  padding: 10px 15px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.homeListButtonText {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}


.homeListText {
  font-size: 20px;
  font-family: "Poppins";
}

.homeListPlayIcon {
  width: 10px;
  height: 10px;
}

html {
  touch-action: manipulation;
}

.paddingTop {
  padding-top: 56px;
  min-height: calc(100vh - 24px);
}

.paddingTopone {
  padding-top: 56px;
}

.categoryHover {
  transform: scale(1);
  transition: transform 0.4s ease-in-out;
}

.rs-input {
  font-size: 14px;
}

.errorred {
  color: #ff0000 !important;
  font-size: 14px;
  margin-top: 10px;
  display: flex;
  font-family: 'Poppins';
}

.noRecord {
  display: flex;
  justify-content: center;
  font-size: 17px;
  font-weight: bold;
  padding: 10px;
}

.css-1nmdiq5-menu:hover {
  background-color: #ff0000 !important;
  color: #ff0000 !important;
}

.css-13cymwt-control {
  padding: 5px 0 5px 0;
  border-radius: 5px;
}

.css-b62m3t-container {
  border-radius: 5px;
}

.css-t3ipsp-control {
  padding: 5px 0 5px 0;
  border-radius: 5px;
}

.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-icon {
  font-size: 25px;
  color: #ffffff;
}

.thumbnails-images {
  width: auto;
  height: 100px;
}

.modal {
  scrollbar-width: none;
}

.hideArrow::after {
  border: none;
}

.absolute-mobile-link {
  position: absolute;
  inset: 0;
  z-index: 3;
}


.comment-avatar {
  width: 50px;
  height: 50px;
}

.comment-name {
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.comment-text {
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  text-align: left;
}

.square {
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(8%, rgba(130, 130, 130, 0.2)),
      color-stop(18%, rgba(130, 130, 130, 0.3)),
      color-stop(33%, rgba(130, 130, 130, 0.2)));
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-squares 2s infinite ease-out;
}

.languageColorAfter::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
}

.languageText {
  font-family: "Poppins";
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  position: absolute;
  bottom: 16px;
  z-index: 1;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.mandatory {
  color: #ff0000;
}

.cursor-pointer {
  cursor: pointer;
}

.rent_video_page_ul_li ul {
  list-style-type: disclosure-closed !important;
}

.maintancesLogo {
  width: 200px;
}

.priceLabel {
  top: 0px;
  left: 0px;
  z-index: 1;
  padding: 6px 10px;
  height: 30px;
}

.priceText {
  font-size: 13px;
  height: 19px;
}

@keyframes wave-squares {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.Toastify__toast-body {
  font-family: "Poppins";
  font-weight: 500;
}

.border-solid {
  border-style: solid;
}

.bottom-border {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.border-width {
  border-width: 1px;
}

.button-color {
  color: #fff !important;
}

.button-bg-color {
  background-color: var(--btn-color) !important;
}

.css-wsp0cs-MultiValueGeneric {
  font-family: "Poppins" !important;
}

.bg-image-gradian::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.Toastify__toast-container {
  z-index: 99999;
}

.before-star::after {
  position: absolute;
  content: "*";
  color: red;
}

button:focus {
  outline: none !important;
}

button.Toastify__close-button {
  background-color: transparent !important;
}

.loaderFixed {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background:
    linear-gradient(0deg, rgba(255, 255, 255, 0.5) 30%, transparent 0 70%, rgba(255, 255, 255, 1) 0) 50%/8% 100%,
    linear-gradient(90deg, rgba(255, 255, 255, 0.25) 30%, transparent 0 70%, rgba(255, 255, 255, 0.75) 0) 50%/100% 8%;
  background-repeat: no-repeat;
  animation: loaderCustom 1s infinite steps(12);
}

.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  border-radius: 100%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}

.loader::after {
  opacity: 0.83;
  transform: rotate(60deg);
}

.header-logo_logo {
  width: 100%;
  height: 100%;
}

@keyframes loaderCustom {
  100% {
    transform: rotate(1turn)
  }
}

.onlineStatus {
  position: fixed;
  top: -50px;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  padding: 10px 15px;
  color: white;
  margin: 0 auto;
  max-width: 190px;
  border-radius: 8px;
  height: 41px;
  z-index: 9999;
  animation: onlineAni 0.08s linear both;
}

@keyframes onlineAni {
  0% {
    top: -50px;
  }

  100% {
    top: 70px;
  }
}

.button-btn {
  background-color: #0fcc1c;
}

input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.termModal .modal-body {
  height: 500px;
  overflow-y: scroll;
}