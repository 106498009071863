.reelvideo-player {
    /* height: 100%; */
    width: 260px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .video-cards {
    position: relative;
    padding: 10px;
}
  
  video {
    width: 100%;
    height:90%;
    min-width: 188px;
    scroll-snap-align: start;
    /* position: relative; */
  }
 
  
  