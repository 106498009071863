.profileThemeTwo.active::before {
    position: absolute;
    content: "";
    bottom: 14px;
    left: 55px;
    height: 3px;
    border-radius: 20px;
    width: 25px;
}

.profileThemeTwo.active {
    opacity: 1;
}

.profileThemeTwo.active .popupDetailLink {
    font-weight: bold;
}

.profileThemeTwo {
    padding: 17px 20px;
}