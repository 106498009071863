.episodeSwiper {
}

.swiperSlideCustom {
  background-color: rgb(45, 48, 56) !important;
  border-radius: 5px;
  /* other styles */
}

/* .episodeSwiper .swiper-slide {
  background-color:rgb(45, 48, 56) !important;
  border-radius: 5px;
  
} */

.episodeSwiper .swiper-button-next {
  color: white;
  right: -7px;
}
.episodeSwiper .swiper-button-prev {
  color: white;
  left: -4px;
}
.episodeSwiper .swiper-button-prev:after, .swiper-button-next:after {
 font-size: 20px;
}
.episodeSwiper .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
  /* right: 20px; */
  left: 3px;
}
.episodeSwiper.swiper {
  margin-left: -15px;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;
}
.episodeSwiper .swiper-button-prev, .swiper-rtl .swiper-button-next {
  /* left: var(--swiper-navigation-sides-offset, 10px); */
  right: auto;
}
.viewEpisode {
  font-size: 16px;
  font-weight: 600;
}
