.objectFitCover {
  object-fit: cover;
}

.viewall-homepage {
  font-size: 16px !important;
}

.swiperHeading {
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0em;
}



.homeListDesc {
  font-family: "Poppins";
  font-size: 11px;
  line-height: 15px;
  font-weight: 400;
  height: 47px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.homeListDesc.active {
  height: 27px;
  -webkit-line-clamp: 2;
}

.homePageCardSpecial {
  font-size: 11px;
  line-height: 15px;
  font-weight: 500;
}

.homePageCardSpecial:last-child::after {
  display: none;
}

.homePageCardSpecial::after {
  content: "";
  width: 4px;
  height: 4px;
  top: 5px;
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  vertical-align: baseline;
  right: -10px;
}

.zIndex {
  position: relative;
  z-index: 0;
}

.zIndex:hover+.zIndex {
  z-index: -1;
}

.paddingBottom {
  padding-bottom: 95px;
}

.gridCategory {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-items: center;
  grid-column-gap: 15px;
}

.gridCategoryItems {
  height: 210px !important;
}


.gridCategoryArrowImage {
  width: 40px;
  height: 30px;
}

.gridCategoryItems:hover .gridCategoryArrowImage {
  -webkit-animation: bounceRight 2s infinite;
  animation: bounceRight 2s infinite;
}

.loaderMain {
  border-radius: 5px;
  overflow: hidden;
}

.radiusTarget {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  height: 200px;
}

.loaderText {
  font-family: "Poppins";
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  text-align: center;
  /* z-index: 2; */
}

@keyframes bounceRight {

  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  40% {
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  60% {
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}



.video-player {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 10px;
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .swiperHeading {
    font-size: 20px;
    line-height: 35px;
    margin-bottom: 5px;
  }
}


@media screen and (min-width: 414px) {
  .gridCategory {
    grid-template-columns: repeat(2, 1fr);
  }

  .gridCategoryItems {
    height: 227px !important;
  }
}

@media screen and (min-width: 510px) {
  .gridCategory {
    grid-template-columns: repeat(3, 1fr);
  }

  .gridCategoryItems {
    height: 210px !important;
  }
}

@media screen and (min-width: 768px) {
  .gridCategory {
    grid-template-columns: repeat(3, 1fr);
  }

  .gridCategoryItems {
    height: 300px !important;
  }
}

@media screen and (min-width: 991px) {
  .gridCategory {
    grid-template-columns: repeat(4, 1fr);
  }

  .gridCategoryItems {
    height: 300px !important;
  }
}