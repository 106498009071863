.mainTrack {
  overflow-x: scroll;
  height: 500px;
  width: 100%;
  scroll-behavior: smooth;
  position: relative;
  display: flex;
  border-radius: 20px;
}

.mainTrack::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

.mainTrack::-webkit-scrollbar-thumb {
  border-radius: 6px;
}

.flickNexsIndicater {
  position: absolute;
  top: 100px;
  width: 3px;
  height: 100%;
  z-index: 3;
  background-color: #2C7A7B;
}

.flickNexsSideBar {
  position: sticky;
  width: 150px;
  left: 0;
  z-index: 10;
}

.timeLineChannelNameHide {
  position: sticky;
  top: 0;
  width: 135px;
  height: 94px;
  /* background: #171923; */
  left: 0;
  z-index: 1;
}

.timeLineChannelName {
  font-size: 20px;
  height: 80px;
  text-transform: capitalize;
  display: flex;
  position: absolute;
  align-items: center;
  width: 133px;
  justify-content: center;
}

.timeLineChannelName::before {
  position: absolute;
  top: 0;
  content: "";
  left: 0;
  height: 80%;
  width: 4px;
  background-color: #92c1ff;
  transform: translate(0%, 12%);
}

.flickNexsTimeLine {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  position: sticky;
  top: 0;
  z-index: 3;
}

.flicknexsDayMaker {
  position: relative;
}

.flickNexsTimeLineMins {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.flicknexsDayMaker .flickNexsDateShow::after {
  position: absolute;
  content: "";
  width: 3px;
  height: 21px;
  background-color: #61f561;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  left: 0px;
  bottom: -27px;
}

.flickNexsTimeLineMins::-webkit-scrollbar {
  height: 3px;
  width: 3px;
  display: none;
}

.flickNexsTimeLineMins .flickNexs24Hours:nth-child(1)>span {
  left: 0;
}

.flickNexsDateShow {
  position: absolute;
  top: 25px;
  z-index: 2;
  left: 0px;
  border-radius: 7px;
  width: 89px;
  border-bottom-left-radius: 0px;
  padding: 3px 5px;
  background: #61f561;
}

.flickNexsDateShow::before {
  bottom: -11px;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
  content: ".";
  height: 12px;
  position: absolute;
  left: 0px;
  width: 12px;
  background-color: #61f561;
  display: block;
}

.flickNexs24Hours {
  position: relative;
  padding-top: 15px;
}


.flickNexs15MinsIndicater {
  width: 250px;
  display: grid;
  height: 80px;
  justify-content: center;
  align-items: flex-end;
  grid-template-columns: repeat(4, 1fr);
}

.flicknexsDayMaker:nth-child(1) .timeValues::after {
  background: #61f561;
  content: "";
  position: absolute;
  top: 23px;
  height: 4px;
  left: 19px;
  width: 4px;
  border-radius: 50%;
  color: #fff;
}

.timeValues {
  position: absolute;
  left: -20px;
  top: 35px;
  color: #a0aec0;
  direction: ltr;
}

.timeLineBreakPoint {
  width: 1px;
  height: 11px;
  background: #a0aec0;
}

.flicknexsListBox {
  position: relative;
  margin-top: 15px;
}

.flickNexsListItem {
  display: flex;
  gap: 20px;
  position: absolute;
  font-size: 16px;
  align-items: center;
  color: #fff;
  height: 75px;
  border-radius: 8px;
  padding: 8px 15px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  background: linear-gradient(to right, #1a202c, #1a202c);
  z-index: 1;
}

.flickNexsListItem.active {
  background: linear-gradient(to right, #6157FF, #EE49FD);
}

.trackImage {
  width: 100px;
  height: 50px;
  object-fit: cover;
  border-radius: 3px;

}

.flickNexsTitle {

  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  line-height: 10px;
  margin: 0;
}

.dayPicker {
  font-size: 12px;
  font-weight: bold;
  width: 115px !important;
  border-radius: 5px;
  border: none;
  padding: 10px;
  font-family: 'Poppins' !important;
}

.dayPicker.picker {
  color: #000;
}