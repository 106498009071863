.video-container {
  position: relative;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  pointer-events: none;
  /* Allows interactions with the video element */
}

.purchaseShow {
  position: absolute;
}

.livepurchase {
  height: 260px !important;
}

.midHeadingText {
  font-size: 28px;
  font-weight: 500;
  text-transform: capitalize;
}

.midHeadingMessage {
  font-size: 22px;
  font-weight: 200;
}

.midHeadingbtn {
  font-size: 16px;
  font-weight: 400;
}

.my-video-dimensions.vjs_video_3-dimensions.vjs-fluid:not(.vjs-audio-only-mode) {
  padding-top: 0;
}

.my-video-dimensions.vjs-theme-city {
  --vjs-theme-city--primary: #00b3ff;
  --vjs-theme-city--secondary: #fff;
}

.my-video-dimensions.vjs-theme-city .vjs-big-play-button {
  width: 60px;
  height: 60px;
  background: none;
  line-height: 60px;
  font-size: 45px;
  border: none;
  top: 50%;
  left: 50%;
  margin-left: -35px;
  color: #ffffff;
  border-radius: 40px;
  transition: 3s;
  align-items: center;
}

.my-video-dimensions.vjs-theme-city.vjs-big-play-button:focus,
.vjs-theme-city:hover .vjs-big-play-button {
  background-color: #0000009c;
  color: #fff;
}

.my-video-dimensions.vjs-fluid {
  padding-top: 0 !important;
  height: 100vh !important;
}

.my-video-dimensions.video-js button {
  background: transparent !important;
}

.my-video-dimensions.video-js .vjs-tech {
  object-fit: contain;
}

.my-video-dimensions.video-js.vjs-playing .vjs-tech {
  pointer-events: all;
}

.vjs-has-started .vjs-big-play-button {
  pointer-events: none;
}

.vjs-paused .vjs-big-play-button {
  pointer-events: all;
}

.my-video-dimensions.vjs-theme-city .vjs-control-bar {
  height: 64px;
}

.my-video-dimensions.video-js .vjs-control-bar {
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
  background: var(--plyr-video-controls-background,
      linear-gradient(transparent, rgba(0, 0, 0, 0.75)));
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  z-index: 3;
}

.my-video-dimensions.vjs-theme-city.vjs-play-control.vjs-icon-placeholder:before {
  border-radius: 4px;
  border: 0px solid #fff0;
  font-size: 30px;
}

.my-video-dimensions.vjs-theme-city .vjs-play-control .vjs-icon-placeholder.vjs-svg-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  fill: currentColor;
  height: 3.8em;
  width: 3.8em;
}

.my-video-dimensions.vjs-button>.vjs-icon-placeholder:before {
  font-size: 3em;
}

.my-video-dimensions.vjs-theme-city .vjs-play-control:hover .vjs-icon-placeholder:before {
  border: 0px solid var(--vjs-theme-fantasy--secondary);
  background: var(--vjs-theme-fantasy--primary);
}

.my-video-dimensions.vjs-theme-city .vjs-volume-panel {
  order: 1;
}

.my-video-dimensions.video-js .vjs-volume-bar {
  margin: 0;
}

.my-video-dimensions.vjs-volume-bar.vjs-slider-horizontal {
  width: 5em;
  height: 0.5em;
}

.my-video-dimensions.video-js .vjs-volume-panel .vjs-volume-control.vjs-slider-active.vjs-volume-horizontal,
.my-video-dimensions.video-js .vjs-volume-panel .vjs-volume-control:active.vjs-volume-horizontal,
.my-video-dimensions.video-js .vjs-volume-panel.vjs-hover .vjs-mute-control~.vjs-volume-control.vjs-volume-horizontal,
.my-video-dimensions.video-js .vjs-volume-panel.vjs-hover .vjs-volume-control.vjs-volume-horizontal,
.my-video-dimensions.video-js .vjs-volume-panel:active .vjs-volume-control.vjs-volume-horizontal,
.my-video-dimensions.video-js .vjs-volume-panel:focus .vjs-volume-control.vjs-volume-horizontal {
  width: 5em;
  height: 2em;
  margin-top: 1.25em;
  top: 1.25em;
}

.my-video-dimensions.vjs-theme-city .vjs-progress-control {
  font-size: 12px;
}

.my-video-dimensions.video-js .vjs-slider {
  border-radius: 3px;
}

.my-video-dimensions.vjs-slider-horizontal .vjs-volume-level {
  height: 0.5em;
}

.my-video-dimensions.video-js .vjs-volume-level:before {
  font-size: 1.4em;
}

.my-video-dimensions.video-js .vjs-progress-holder .vjs-load-progress,
.my-video-dimensions.video-js .vjs-progress-holder .vjs-load-progress div,
.my-video-dimensions.video-js .vjs-progress-holder .vjs-play-progress {
  border-radius: 3px;
}

.my-video-dimensions.vjs-theme-city .vjs-play-progress:before {
  border-radius: 0.8em;
}

.my-video-dimensions.vjs-theme-city .vjs-play-progress:before {
  background-color: white;
}

.my-video-dimensions.video-js .vjs-progress-holder {
  flex: auto;
  transition: all 0.2s;
  height: 0.2em;
}

.my-video-dimensions.video-js .vjs-time-tooltip {
  border-radius: 1.3em;
  top: -3.8em;
}

.my-video-dimensions.video-js .vjs-progress-control:hover .vjs-progress-holder:focus .vjs-time-tooltip,
.my-video-dimensions.video-js .vjs-progress-control:hover .vjs-time-tooltip {
  font-size: 0.8em;
}

.my-video-dimensions.vjs-menu li {
  padding: 0.5em 0;
  line-height: 1.5em;
  font-size: 1.5em;
}

.my-video-dimensions.vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  background-color: #2b333f;
  background-color: rgb(0 0 0 / 70%);
  position: absolute;
  width: 100%;
  bottom: 3.5em;
  max-height: 25em;
  border-radius: 5px;
}

.vjs-icon-picture-in-picture-enter:before,
.my-video-dimensions.video-js .vjs-picture-in-picture-control .vjs-icon-placeholder:before {
  line-height: 62.57px;
}

.vjs-icon-play,
.my-video-dimensions.video-js .vjs-play-control .vjs-icon-placeholder,
.my-video-dimensions.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  font-family: VideoJS;
  font-weight: normal;
  font-style: normal;
}

.my-video-dimensions.video-js .vjs-fullscreen-control .vjs-icon-placeholder,
.my-video-dimensions.video-js .vjs-fullscreen-control .vjs-icon-placeholder,
.vjs-icon-fullscreen-enter {
  font-size: 1.8em;
}

.vjs-icon-fullscreen-enter:before,
.my-video-dimensions .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
  content: "\f108";
  line-height: 2.1em;
}

.vjs-icon-fullscreen-enter:before,
.my-video-dimensions.video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
  content: "\f108";
  font-size: 25px;
  margin-top: 8px;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.my-video-dimensions.vjs-theme-city .vjs-progress-control .vjs-progress-holder,
.my-video-dimensions.vjs-theme-city .vjs-progress-control:hover .vjs-progress-holder {
  font-size: 1.5em;
}

.my-video-dimensions.vjs-play-progress .vjs-svg-icon {
  position: absolute;
  top: -0.7em;
  right: -0.4em;
  width: 1.7em;
  height: 1.5em;
  pointer-events: none;
  line-height: 0.15em;
  z-index: 1;
}

.my-video-dimensions.video-js .vjs-remaining-time {
  display: flex;
  align-items: center;
}


.my-video-dimensions.vjs-paused .vjs-big-play-button {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.5s;
}

.my-video-dimensions.vjs-big-play-button .vjs-svg-icon {
  width: 1.2em;
  height: 1.2em;
  position: absolute;
  top: 50%;
  left: 50%;
  line-height: 0;
  transform: translate(-50%, -50%);
}

.my-video-dimensions.vjs-theme-city .vjs-fullscreen-control {
  order: 3;
}

.fds {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: x-large;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.my-video-dimensions.video-js .vjs-mute-control .vjs-icon-placeholder.vjs-svg-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  fill: currentColor;
  height: 2.8em;
  width: 2.8em;
}

.my-video-dimensions.vjs-title-bar {
  background: none;
  cursor: pointer;
  display: block !important;
  padding-top: 0.666em;
  padding-left: 51px;
  font-size: 24px;
  font-family: "Poppins";
  font-weight: 200;
}

.my-video-dimensions.vjs-play-progress .vjs-svg-icon {
  position: absolute;
  top: -0.35em;
  right: -0.4em;
  width: 0.9em;
  height: 0.9em;
  pointer-events: none;
  line-height: 0.1em;
  z-index: 1;
}

.my-button {
  display: block;
}

.my-video-dimensions.video-js .vjs-modal-dialog {
  background: #454545;
  overflow: visible;
  box-shadow: 5px 5px 10px gray;
  border-radius: 2px;
}

.vjs-modal-dialog .vjs-modal-dialog-content {
  padding: 20px 24px;
  z-index: 1;
  display: flex;
  position: fixed;
}

.my-video-dimensions.video-js.vjs-quality-selector {
  display: block;
}

.my-video-dimensions.video-js.vjs-http-source-selector {
  display: block;
}

.my-video-dimensions.vjs-icon-cog:before {
  content: "\f114";
  font-size: x-large;
}

.backbutton {
  pointer-events: auto;
  position: absolute;
  color: white;
  top: 0;
  background: transparent;
  z-index: 1;
}

.custom-play-pause-button {
  pointer-events: auto;
  position: absolute;
  color: white;
  top: 47.4%;
  left: 48.5%;
  background: transparent;
  z-index: 1;
  padding: 0px;
}

.custom-skip-forward-button {
  pointer-events: auto;
  position: absolute;
  color: white;
  top: 47.4%;
  left: 65%;
  background: transparent;
  z-index: 1;
}

.custom-skip-backward-button {
  pointer-events: auto;
  position: absolute;
  color: white;
  top: 47.4%;
  left: 30%;
  background: transparent;
  z-index: 1;
}

.custom-reload-button {
  position: absolute;
  z-index: 1;
}

.custom-fullscreen-button {
  position: absolute;
  pointer-events: auto;
  z-index: 1;
  top: 0;
  right: 0;
  padding-top: 2em;
  padding-right: 2em;
}

.titlebutton {
  pointer-events: auto;
  position: absolute;
  color: white;
  top: 0;
  background: transparent;
  z-index: 1;
  background: none;
  cursor: pointer;
  display: block !important;
  padding-top: 0.666em;
  padding-left: 51px;
  font-size: 24px;
  font-family: "Poppins";
  font-weight: 200;
}

.my-video-dimensions.video-js .vjs-control.kk {
  position: relative;
  text-align: center;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 1.2em;
  flex: none;
  background: none;
  line-height: 60px;
  font-size: 25px;
  border: none;
  top: -9px;
  left: 227%;
  margin-top: -35px;
  margin-left: -35px;
  color: #ffffff;
}

.ad-information-card {
  position: absolute;
  top: 70px;
  right: 30px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  z-index: 100;
}

.skipRecapWrapper {
  bottom: 70px;
  right: 50px;
}

.skipText {
  font-size: 15px;
}

.endCard {
  position: relative;
  z-index: 1;
}

.endCardBanner {
  width: 100%;
  height: 160px;
  overflow: hidden;
}

.endCardImage {
  animation: zoomIn 0.5s linear;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.my-video-dimensions.video-js .vjs-control.kkk {
  position: relative;
  text-align: center;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 1.2em;
  flex: none;
  background: none;
  line-height: 60px;
  font-size: 25px;
  border: none;
  top: -9px;
  left: -190%;
  color: #ffffff;
}

.my-video-dimensions.video-js:hover .vjs-big-play-button {
  border-color: #fff;
  background-color: #73859f;
  background-color: rgba(115, 133, 159, 0.5);
  transition: all 0s;
}

.my-video-dimensions.video-js.vjs-user-active.vjs-big-play-button {
  display: block !important;
  visibility: visible;
  opacity: 0;
  transition: visibility 1s, opacity 1s;
}

.my-video-dimensions.vjs-controls-disabled .vjs-big-play-button .vjs-icon-placeholder,
.my-video-dimensions.vjs-has-started .vjs-big-play-button .vjs-icon-placeholder,
.my-video-dimensions.vjs-using-native-controls .vjs-big-play-button .vjs-icon-placeholder {
  content: "\f103";
}

.my-video-dimensions.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder {
  content: "\f103" !important;
}

.my-video-dimensions.vjs-paused .vjs-big-play-button {
  display: block !important;
}

.my-video-dimensions.video-js:hover .vjs-big-play-button,
.my-video-dimensions.video-js .vjs-big-play-button:focus {
  border-color: #fff;
  background-color: transparent;
}

/* .my-video-dimensions .vjs-audio-button {
  display: none !important;
} */
.my-video-dimensions .vjs-subtitles-button {
  display: block !important;
}

.vjs-captions-button {
  display: block !important;
}

.my-video-dimensions.vjs-button:focus-visible {
  outline: transparent;
}

.my-video-dimensions.video-js .vjs-volume-panel .vjs-volume-control {
  visibility: visible;
  opacity: 0;
  width: 1px;
  height: 1px;
  margin-left: 9px;
}

.my-video-dimensions.video-js.vjs-volume-level {
  background-color: #095ae5;
}

.my-video-dimensions.video-js.vjs-playing .vjs-big-play-button .vjs-icon-placeholder:before {
  content: "\f103";
}

.vjs-icon-play,
.my-video-dimensions .video-js .vjs-play-control .vjs-icon-placeholder,
.my-video-dimensions.video-js .vjs-big-play-button .vjs-icon-placeholder::before {
  font-family: VideoJS;
  font-weight: normal;
  font-style: normal;
  font-size: 36px;
}

.my-video-dimensions.video-js .vjs-big-play-button {
  font-size: 3em;
  line-height: 1.5em;
  height: 1.63332em;
  width: 3em;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0;
  margin-top: -0.81666em;
  margin-left: -1.5em;
  cursor: pointer;
  opacity: 1;
  border: none;
  background-color: transparent;
  border-radius: none;
  transition: all 0.4s;
}

.my-video-dimensions.video-js:hover .vjs-big-play-button,
.my-video-dimensions.video-js .vjs-big-play-button:focus {
  border-color: #fff;
  background-color: transparent;
  transition: all 0s;
}

.vjs-icon-play:before,
.my-video-dimensions .video-js .vjs-play-control .vjs-icon-placeholder:before,
.my-video-dimensions.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  content: "\f101";
}

.vjs-loading-spinner-myvideo {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.85;
  text-align: left;
  border: 0.3em solid #095ae5;
  box-sizing: border-box;
  background-clip: padding-box;
  width: 5em;
  height: 5em;
  border-radius: 50%;
  visibility: hidden;
}

.vjs-seeking .vjs-loading-spinner-myvideo,
.vjs-waiting .vjs-loading-spinner-myvideo {
  display: block;
  animation: vjs-spinner-showw 0s linear 0.3s forwards;
}

.vjs-error .vjs-loading-spinner-myvideo {
  display: none;
}

.vjs-loading-spinner-myvideo:before,
.vjs-loading-spinner-myvideo:after {
  content: "";
  position: absolute;
  margin: -0.3em;
  box-sizing: inherit;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  opacity: 1;
  border: inherit;
  border-color: transparent;
  border-top-color: #095ae5;
}

.vjs-seeking .vjs-loading-spinner-myvideo:before,
.vjs-seeking .vjs-loading-spinner-myvideo:after,
.vjs-waiting .vjs-loading-spinner-myvideo:before,
.vjs-waiting .vjs-loading-spinner-myvideo:after {
  animation: vjs-spinner-spinn 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
    vjs-spinner-fadee 1.1s linear infinite;
}

.vjs-seeking .vjs-loading-spinner-myvideo:before,
.vjs-waiting .vjs-loading-spinner-myvideo:before {
  border-top-color: deepskyblue;
}

.vjs-seeking .vjs-loading-spinner-myvideo:after,
.vjs-waiting .vjs-loading-spinner-myvideo:after {
  border-top-color: deepskyblue;
  animation-delay: 0.44s;
}

@keyframes vjs-spinner-showw {
  to {
    visibility: visible;
  }
}

@keyframes vjs-spinner-spinn {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes vjs-spinner-fadee {
  0% {
    border-top-color: #095ae5;
  }

  20% {
    border-top-color: #095ae5;
  }

  35% {
    border-top-color: deepskyblue;
  }

  60% {
    border-top-color: #095ae5;
  }

  100% {
    border-top-color: #095ae5;
  }
}

.my-video-dimensions.vjs-live .vjs-progress-control {
  display: flex;
}

.my-video-dimensions.video-js .vjs-live-control {
  display: contents;
  align-items: center;
  line-height: 3em;
  padding: 10px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
}

.vjs-menu-button-popup .vjs-menu {
  display: none;
  position: absolute;
  bottom: 0;
  width: 10em;
  left: -3em;
  height: 0em;
  margin-bottom: 3.5em;
  border-top-color: rgba(43, 51, 63, 0.7);
}

.vjs-menu-button-popup .vjs-menu::after {
  content: "";
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: #2b333f transparent transparent transparent;
}

.vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  position: absolute;
  width: 100%;
  bottom: 0.9em;
  max-height: 15em;
  background-color: #2b333f;
  border-radius: 5px;
}

.vjs-menu li {
  list-style: none;
  margin: 0;
  padding: 0.6em 0;
  line-height: 1.4em;
  font-size: 1.4em;
  text-align: center;
  text-transform: capitalize;
}

.vjs-menu li.vjs-selected,
.vjs-menu li.vjs-selected:focus,
.vjs-menu li.vjs-selected:hover,
.js-focus-visible .vjs-menu li.vjs-selected:hover {
  color: white;
  background-color: #095ae5;
}

.vjs-menu li.vjs-menu-item:focus,
.vjs-menu li.vjs-menu-item:hover,
.js-focus-visible .vjs-menu li.vjs-menu-item:hover {
  background-color: #095ae5;
}

.my-video-dimensions.video-js .vjs-play-progress:before {
  font-size: 0.8em;
  position: absolute;
  right: -0.5em;
  z-index: 1;
  text-align: center;
  color: white;
  background-color: white;
}

.my-video-dimensions.video-js .vjs-slider {
  position: relative;
  cursor: pointer;
  padding: 0;
  margin: 0 0.45em 0 0.45em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  background-color: #73859f;
}

.my-video-dimensions.video-js .vjs-load-progress div {
  background: #73859f;
}

.video-js .vjs-volume-panel.vjs-hover .vjs-volume-control,
.video-js .vjs-volume-panel:active .vjs-volume-control,
.video-js .vjs-volume-panel:focus .vjs-volume-control,
.video-js .vjs-volume-panel .vjs-volume-control:active,
.video-js .vjs-volume-panel.vjs-hover .vjs-mute-control~.vjs-volume-control,
.video-js .vjs-volume-panel .vjs-volume-control.vjs-slider-active {
  visibility: visible;
  opacity: 1;
  position: relative;
  transition: none;
}

.vjs-volume-bar.vjs-slider-vertical {
  width: 0.3em;
  height: 8em;
  margin: 1.35em auto;
}

@media only screen and (max-width: 600px) {
  .my-video-dimensions.video-js .vjs-tech {
    object-fit: contain;
  }
}

@keyframes backgroundSlide {
  0% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
}

@-webkit-keyframes backgroundSlide {
  0% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
}

@-moz-keyframes backgroundSlide {
  0% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
}

@-o-keyframes backgroundSlide {
  0% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.next-videos-slider {
  display: flex;
  overflow: hidden;
  padding: 20px;
  pointer-events: auto;
  position: absolute;
  color: white;
  top: 68.4%;
  /* left: ; */
  background: transparent;
  z-index: 1;
}

.video-thumbnail {
  width: 212px;
  height: 119px;
  object-fit: cover;
  pointer-events: auto;
  background: transparent;
  z-index: 1;
  border-radius: 8px;
}

.video-title {
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
}

.tog {
  pointer-events: auto;
  position: absolute;
  color: white;
  top: 80.4%;
  left: 48.6%;
  background: transparent;
  z-index: 1;
}

/* Theme-Video-Player */
.theme-video-dimensions.vjs-fluid:not(.vjs-audio-only-mode) {
  padding-top: 46.25%;
}

.theme-video-dimensions.video-js .vjs-tech {
  object-fit: cover;
}

.swiper-wrapper-container {
  position: relative;
  width: 100%;
  top: 57%;
}

.playerClose {
  font-size: 25px !important;
  color: white;
  position: absolute;
  z-index: 1;
}

.close-button {
  position: absolute;
  top: -38px;
  right: 21px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 16px;
  cursor: pointer;
  z-index: 10;
}

.vjs-icon-captions:before,
.video-js:lang(en) .vjs-subs-caps-button .vjs-icon-placeholder:before,
.video-js:lang(fr-CA) .vjs-subs-caps-button .vjs-icon-placeholder:before,
.video-js .vjs-captions-button .vjs-icon-placeholder:before {
  content: "\f10c";
}

.my-video-dimensions.video-js.vjs-subs-caps-button+.vjs-menu .vjs-captions-menu-item .vjs-menu-item-text .vjs-icon-placeholder {
  vertical-align: middle;
  display: none;
  margin-bottom: -0.1em;
}

.vjs-button>.vjs-icon-placeholder:before {
  font-size: 1.8em;
  /* line-height: 1.67; */
}

.my-video-dimensions.video-js .vjs-subs-caps-button+.vjs-menu .vjs-captions-menu-item .vjs-menu-item-text .vjs-icon-placeholder {
  vertical-align: middle;
  display: none;
  margin-bottom: -0.1em;
}

.my-video-dimensions .vjs-button>.vjs-icon-placeholder:before {
  position: relative;
  font-size: 25px;
}

.my-video-dimensions .vjs-remaining-time-display {
  font-size: 15px;
}

.my-video-dimensions .vjs-playback-rate .vjs-playback-rate-value {
  top: 17px !important;
}

.play-skip-button-center {
  inset: 0;
  margin: auto auto;
  height: 20px;
  z-index: 1;
  display: flex;
}

.vjs-marker {
  position : absolute;
  top : 30px;
  width : 4px;
  height : 0.4em;
  background-color : #fbeb2b;
}

#vjs_video_3_ima-countdown-div{
  display: none !important;
}

#vjs_video_1309_ima-ad-container video {
  top: -42px !important;
}

.video-js .vjs-volume-vertical:hover .vjs-volume-tooltip, .video-js .vjs-volume-vertical:hover 
  .vjs-progress-holder:focus .vjs-volume-tooltip {
  left: -4em;
  top: -10px;
}