.transcation_list_bg {
  /* background-color: #fff; */
}

.closeBtn {
  top: 89.5%;
  position: absolute;
  left: 22%;
  background: transparent;

  color: black;
}

/* #pageDropDown {
  display: none;
} */

.searchPageIconFaSearchtranssearchPageIconFaSearchtrans {
  position: absolute;
  top: 74px;
  left: 142px;
  width: 25px;
  height: 33px;
  z-index: 1;
}

/* Add this to your CSS file or in a style tag */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  /* Enables horizontal scroll */
  -webkit-overflow-scrolling: touch;
  /* Enables smooth scrolling on iOS */
}

.table-responsive Table {
  width: 100%;
  /* Ensures table width is 100% */
  min-width: 600px;
  /* Optional: Add a minimum width if needed */
}