.episodeContainer {
  overflow-x: scroll;
}

.episodeList {
  width: 1000px;
}

.episodeContainer::-webkit-scrollbar {
  display: none;
}

.season_episode_header th {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.dropzoneStyle {
  border: 2px dashed#cccccc;
  border-radius: 10px;
  height: 100px;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.allserieschannel .modal-dialog {
  top: 20%;
}