.mediavideo media-outlet {
  width: 50%;
}

.dropzonestyle {
  border: 2px dashed#cccccc;
  border-radius: 10px;
  height: 100px;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.imagedropseason {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-style: dashed;
  border-color: #cbd5e1;
  cursor: pointer;
}