.rtl .musicSideBar {
    right: 0;
    left: auto;
}

.rtl .rightMusicContainer {
    margin-right: auto;
    margin-left: unset;
}

.rtl .playlistHeading::before {
    right: 11px;
    left: auto;
}

.rtl .trackPlaylist {
    padding-right: 10px;
    padding-left: unset;
}

.rtl .purchaseClose {
    left: 17px;
    right: auto;
}

@media screen and (max-width: 991px) {
    .rtl .musicSideBar {
        right: -60% !important;
        left: auto !important;
        transition: right 0.6s linear !important;
    }

    .rtl .musicSideBar.activeSideBar {
        right: 0% !important;
        left: auto !important;
    }
}