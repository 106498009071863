.homePageBannerSwiperMulti .swiper-slide {
    width: 100%;
    height: 100%;
    position: relative;

}

.homePageBannerSwiperMulti .bannerContainer {
    overflow: hidden;
    position: relative;
    border-radius: 10px;
}

.homePageBannerSwiperMulti .trailerNot {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}

.homePageBannerSwiperMulti .bannerContainer .vjs-theme-fantasy {
    overflow: hidden;
}

.homePageBannerSwiperMulti .bannerContainer .vjs-theme-fantasy>video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.homePageBannerSwiperMulti .swiper-slide-prev>.bannerContainer {
    opacity: 0.4;
    border-radius: 10px;
    overflow: hidden;
}

.homePageBannerSwiperMulti .swiper-slide-next>.bannerContainer {
    opacity: 0.4;
    border-radius: 10px;
    overflow: hidden;
}

.homePageBannerSwiperMulti .swiper-slide-shadow-left,
.homePageBannerSwiperMulti .swiper-slide-shadow-right {
    background-image: none !important;
}



.homePageBannerSwiperMulti .swiper-button-prev,
.homePageBannerSwiperMulti .swiper-button-next {
    color: #fff;
}

.homePageBannerSwiperMulti .swiper-button-prev::after,
.homePageBannerSwiperMulti .swiper-button-next::after {
    font-size: 17px;
    font-weight: bold;
}

.homePageBannerSwiperMulti .swiper-button-next {
    right: 100px;
}

.homePageBannerSwiperMulti .swiper-button-prev {
    left: 100px;
}



.bannerPopContainer {
    padding: 10px 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    /* background-image: linear-gradient(-90deg, transparent, rgba(0, 0, 0, 1)); */
    transition: background-image 2s linear;
    z-index: 999;
}

.homePageBannerSwiperMulti .swiper-slide-active .bannerPopContainer:hover {
    background: transparent;
}

.bannerPopTitle {
    font-weight: 600;
    font-family: 'Poppins';
    font-size: 18px;
}

.bannerPopDesc {
    font-weight: 300;
    font-family: 'Poppins';
    font-size: 9px;
    line-height: 16px;
    margin-top: 3px;
    height: 85px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.bannerPopSpecial {
    font-weight: 500;
    font-family: 'Poppins';
    font-size: 10px;
    display: inline-block;
    margin: 3px 5px 0px 0px;

}

.loadingCircle {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border-width: 2px;
    border-color: #fff;
    border-style: solid;
    border-right-color: transparent !important;
    animation: rotate 1s linear infinite;
}

.bannerLogoImage {
    border-radius: 6px;
}

.bannerButton {
    width: 50px;
    z-index: 1;
    overflow: hidden;
}

.homeBannerPlayImage {
    /* width: 17px;
    height: 17px; */
    width: 38px;
    height: 44px;
    animation: popover 5s ease-in-out infinite;
}

.homePageBannerSwiperMulti .vjs-poster {
    display: block !important;
    background-size: cover;
}

/* .homePageBannerSwiperMulti .vjs-theme-fantasy {
    background-color: #000;
} */

.homePageBannerSwiperMulti .swiper-slide-active .hide-poster+.vjs-poster {
    display: none !important;
}

.homePageBannerSwiperMulti .vjs-poster img {
    object-fit: cover !important;
}

.homePageBannerSwiperMulti .swiper-slide-active {
    position: relative;
    z-index: 1;
}

.homePageBannerSwiperMulti .vjs-loading-spinner:before {
    top: 0 !important;
}

.homePageBannerSwiperMulti .vjs-loading-spinner:after {
    display: none !important;
}

.volumnButtonImage {
    position: absolute;
    top: 13px;
    left: 15px;
    z-index: 9999;
}

@keyframes popover {

    0%,
    30%,
    50%,
    70%,
    90% {
        transform: scale(1);
    }

    20%,
    40%,
    60%,
    80%,
    100% {
        transform: scale(1.2);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }

}

.homePageBannerSwiperMulti .swiper-slide-prev::before,
.homePageBannerSwiperMulti .swiper-slide-next::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.3);
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.retryButton {
    inset: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.retryButtonSize {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.languageWrapper:hover .moreLanguageBox {
    display: block !important;
}

@media screen and (max-width:769px) {
    .homePageBannerSwiperMulti {
        padding: 0px 10px;
    }

    .bannerPopContainer {
        border-radius: 10px;
    }
}

@media screen and (min-width:768px) and (max-width:991px) {

    .homePageBannerSwiperMulti .swiper-button-next {
        right: 40px;
    }

    .homePageBannerSwiperMulti .swiper-button-prev {
        left: 40px;
    }
}

@media screen and (min-width:426px) and (max-width:767px) {
    .homePageBannerSwiperMulti .swiper-button-next {
        right: 0px;
    }

    .homePageBannerSwiperMulti .swiper-button-prev {
        left: 0px;
    }
}

@media screen and (max-width:769px) {

    .homePageBannerSwiperMulti .swiper-button-prev,
    .homePageBannerSwiperMulti .swiper-button-next {
        display: none;
    }

    .bannerLogoImage {
        width: 100px !important;
        height: auto !important;
        object-fit: contain;
        border-radius: 6px;
    }
}

@media screen and (min-width:769px) {
    .homePageBannerSwiperMulti .swiper-slide-active>.bannerContainer {
        transform: scale(1.5);
    }

    .homePageBannerSwiperMulti .swiper-slide-prev>.bannerContainer {
        transform: scale(1.3);
    }

    .homePageBannerSwiperMulti .swiper-slide-next>.bannerContainer {
        transform: scale(1.3);
    }

    .homePageBannerSwiperMulti {
        width: 100%;
        height: 570px;
        padding: 100px 0px;
        position: relative;
    }

    .homePageBannerSwiperMulti .swiper-slide-active>.bannerContainer {
        border-radius: 10px;
    }

    .bannerPopContainer {
        padding: 20px;
    }

    .bannerLogoImage {
        width: 100px !important;
        height: 60px !important;
        object-fit: contain;
    }

    .movieSwiper .bannerLogoImage {
        width: 150px;
        height: 100px;
    }

}



@media screen and (max-width:769px) and (min-width:425px) {
    .bannerPopDesc {
        font-size: 13px;
        line-height: 19px;
    }
}

@media screen and (max-width:425px) {
    .bannerLogoImage {
        display: none;
    }

    .homePageBannerSwiperMulti .swiper-button-next {
        right: 0px;
    }

    .homePageBannerSwiperMulti .swiper-button-prev {
        left: 0px;
    }
}

@media screen and (max-width:565px) {
    .bannerPopContainer>div:nth-child(1) {
        width: 80% !important;
    }

    .bannerButton {
        width: 30px;
        height: 15px;
    }

    .homeBannerPlayImage {
        width: 10px;
        height: 10px;
    }

    .bannerPopContainer {
        padding: 10px 15px;
    }
}

/* .vjs_video_3-dimensions {
    width: 100%;
    height: 1080px;
} */