.homePageSwiper.swiper {
    width: 100%;
    height: 100%;
    overflow: revert !important;
}

.homePageSwiper .swiper-button-prev,
.homePageSwiper .swiper-button-next {
    top: 0;
    color: #fff;
    z-index: 3;
    height: 100%;
}

.homePageSwiper .swiper-button-prev::after,
.homePageSwiper .swiper-button-next::after {
    font-size: 22px;
    font-weight: bold;
}

.homePageSwiper .swiper-button-next.swiper-button-disabled,
.homePageSwiper .swiper-button-prev.swiper-button-disabled {
    /* pointer-events: stroke !important; */
    opacity: 0 !important;
}

.homePageSwiper.navigationMedium .swiper-button-next {
    left: 300px;
}

.homePageSwiper.navigationMedium .swiper-button-prev {
    left: 250px;
}

.homePageSwiper.navigationBiggest .swiper-button-next {
    left: 444px;
}

.homePageSwiper.navigationBiggest .swiper-button-prev {
    left: 385px;
}

.homePageSwiper.navigationSmall .swiper-button-next {
    left: 150px;
}

.homePageSwiper.navigationSmall .swiper-button-prev {
    left: 95px;
}

.homePageSwiper .swiper-slide {
    width: 100%;
    height: 305px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    z-index: 0;
}

.homePageSwiper .swiper-slide-active .homeSwiperContainer {
    left: 30px;
}

.homePageSwiper .swiper-slide:hover .homeSwiperContainer {
    visibility: visible;
    transform: scale(1.2);
    transition: all 0.4s ease-in-out;
    transition-delay: 0.2s;
}

.homePageSwiper .swiper-slide:hover {
    z-index: 2;
}

.homePageSwiper.categorySizeSmall .swiper-slide {
    height: 170px !important;
}

.homePageSwiper.categorySizeSmall .swiper-slide:hover .categoryHover {
    transform: scale(1.1);
}

.homePageSwiper.categorySizeSmall .swiper-slide:hover .categoryHover {
    transform: scale(1.1);
    border-radius: 5px;
}

.homePageSwiper.livestream .swiper-slide {
    height: 180px !important;
    text-align: center;
    font-size: 18px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

.homePageSwiper .moreLike .swiper-slide {
    height: 100%;
    text-align: center;
    font-size: 18px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    object-fit: cover;
}

.homePageSwiper.livestream .swiper-slide:hover .categoryHover {
    transform: scale(1.1);
}

.homeSwiperContainer {
    position: absolute;
    min-height: 328px;
    width: 250px;
    background-color: #16181f;
    top: 5px;
    left: 0;
    transition: transform 0.04s linear;
    visibility: hidden;
    overflow: hidden;
    border-radius: 5px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.homeSwiperContainer.theme-two-active,
.hoverCardContainer.theme-two-active {
    padding: 13px !important;
}

.homeSwiperContainer.theme-two-active::before,
.hoverCardContainer.theme-two-active::before {
    background-image: none;
}

.homeSwiperContainer.theme-two-active .homeListImage::after,
.hoverCardContainer.theme-two-active .homeListImage::after {
    background: none;
}

.homeSwiperContainer.theme-two-active .homePageCardHeading.active,
.hoverCardContainer.theme-two-active .homePageCardHeading.active {
    width: 50px;
}

.homeSwiperContainer .dropdown-item:hover,
.homeSwiperContainer .dropdown-item:focus,
.hoverCardContainer .dropdown-item:hover,
.hoverCardContainer .dropdown-item:focus {
    background-color: transparent;
}

.homeSwiperContainer::before {
    position: absolute;
    content: "";
    background-image: linear-gradient(to bottom, transparent, #16181f, #16181f);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}