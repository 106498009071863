@import url('./rtl-music.css');

.widthBanner {
    width: 70%;
}

.widthList {
    width: 30%;
}

.musicHeight {
    height: calc(100vh - 16px);
}

.musicListHeight {
    /* height: calc(100vh - 82px); */
    /* height: calc(100vh - 160px); */
    height: calc(100vh - 16px);
    overflow-y: scroll;
}

.musicListHeight::-webkit-scrollbar,
.musicListHeightPanel::-webkit-scrollbar {
    display: none;
}

.music-player-mini {
    height: 229px;
    position: relative;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}

.music-player-mini::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
}

.miniPlayerImage {
    width: 50px;
    height: 50px !important;
}

.zIndexOne {
    z-index: 1;
}

.slashCode {
    position: absolute;
    width: 2px;
    height: 24px;
    top: 0px;
    left: 15px;
    background: #fff;
    border-radius: 11px;
    transform: rotate(45deg);
}

.miniPlayerHead {
    height: 20px;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.miniPlayerText {
    white-space: nowrap;
    display: inline-block;
    padding-left: 100%;
    transition: padding-left 1s linear;
    animation: marquee 20s linear infinite;
}

@keyframes marquee {
    0% {
        transform: translate(0);
    }

    100% {
        transform: translateX(-100%);
    }

}

.playButtonMain:focus-visible {
    outline: none;
}

.musicSideBar {
    width: 85px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    transition: left 0.6s linear;
}

.musicSideBar.activeFloat {
    height: calc(100vh - 62px);
}

.musicSideBar .music-link>span {
    font-size: 14px;
}


.rightMusicContainer {
    width: calc(100% - 77px);
    margin-left: auto;
}

.homeIcon {
    width: 22px;
    height: 22px;
}

.music-link {
    margin-bottom: 60px;
}

.music-link:last-child {
    margin-bottom: 0;
}

.musicSideBarIcons {
    width: 20px;
    height: 20px;
}

.music-link:hover {
    /* color: #fff; */
}

.searchPageCard:hover .musicPlayButton {
    visibility: visible;
}

.music-link.active {
    /* background-color: rgb(31, 34, 42);
    color: #fff; */
}

.artistsImageMusic {
    width: 40px;
    height: 40px !important;
}

.audioLink {
    font-size: 15px;
}

.mini-heading {
    font-size: 12px;
}

.musicFollowBtn {
    border-radius: 20px;
    width: 100px;
    font-size: 14px;
}

.playingcategory {
    border-radius: 30px;
}


.musicDescription {
    font-size: 13px;
    height: 53px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.artistPop {
    width: 35px;
    height: 35px;
    border-radius: 30px;
    object-fit: cover;
    margin-left: -15px;
}

.artistPop:first-child {
    margin: 0 !important;
}

.mini-wrapper {
    position: sticky;
    top: 0;
    z-index: 2;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.musicArrow {
    width: 26px;
    height: 26px;
}

.musicGoBackBtn:disabled {
    background-color: transparent !important;
    cursor: not-allowed;
}

.musicSideBar .dropdown-toggle::after {
    display: none;
}

.playlistWrapper {
    max-width: 350px;
    width: 100%;
    padding: 18px;
    border-radius: 10px;
}

.purchaseBtn {
    width: 120px;
}

.priceLabelRadius {
    border-top-left-radius: 5px;
    border-top-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 15px;
}

.priceLabelViewPage {
    width: 85px !important;
}

.othersTime {
    width: 60px;
    text-align: center;
}

.active-description {
    font-weight: 400 !important;
}

.listPanelPrice .priceText {
    width: 100%;
    text-align: right !important;
}

.listPanelPrice {
    width: 100%;
}

.list-panel-active-music {
    font-weight: 600;
}

.musicListHeightPanel.activeFloat {
    height: calc(100vh - 150px);
}

.musicListHeightPanel {
    overflow-y: scroll;
    height: calc(100vh - 80px);
}

.musicListHeight.activeFloat {
    height: calc(100vh - 150px);
}

.playlistHeading {
    font-weight: bold;
    font-size: 22px;
}

.playlistHeading::before {
    content: '';
    width: 4px;
    height: 56%;
    left: 11px;
    border-radius: 10px;
    top: 13px;
    position: absolute;
}

.playlistCount {
    font-size: 16px;
    font-weight: 500;
}

.trackPlaylist {
    padding-left: 10px;
}

.slide-bottom {
    animation: slide-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-left {
    animation: slide-left 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-right {
    animation: slide-right 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-bottom {

    0%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    30% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
}


@keyframes slide-left {

    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    30% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }
}

@keyframes slide-right {

    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    30% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
}

.marginER {
    margin: 0px 13px;
}

.rotate {
    transform: rotate(-180deg);
}

.playbtnembed {
    width: 35px;
    height: 35px;
}

.audioListNameTrack {
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    font-family: "Poppins";
}

@media screen and (max-width:991px) {

    .musicHeight,
    .musicListHeight {
        height: 100%;
    }

    .mobileSideBar {
        top: 0;
        width: 100%;
        z-index: 10;
    }



}

@media screen and (max-width:768px) {

    .musicSideBar.activeSideBar {
        left: 0 !important;
    }

    .musicSideBar {
        left: -50% !important;
        top: 53px !important;
        width: 50% !important;
        backdrop-filter: blur(5px);
    }

    .musicSideBar.activeFloat {
        height: calc(100vh - 112px) !important;
    }

    .musicSideBar.inActiveFloat {
        height: calc(100vh - 52px);
    }


    .musicArrow {
        width: 25px;
        height: 25px;
    }

    .homeIcon {
        width: 18px;
        height: 18px;
    }

    .ListplayButton {
        position: absolute;
        top: 0px;
        left: 8px;
        border-radius: 6px;
        width: 50px;
        height: 50px;
        display: flex;
        z-index: 9;
        align-items: center;
        justify-content: center;
    }

    .rightMusicContainer {
        width: 100%;
    }

    .paddingTopMusic {
        padding-top: 66px;
    }
}

@media screen and (min-width:375px) {}

@media screen and (min-width:426px) {
    .playlistWrapper {
        max-width: 450px;
    }

    .purchaseBtn {
        width: 180px;
    }

}