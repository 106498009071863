.bootstrapTable .react-bs-table-sizePerPage-dropdown {
    position: relative;
  }
  
  .bootstrapTable .react-bs-table-sizePerPage-dropdown .dropdown-menu {
    bottom: 32px;
  }
  
  .bootstrapTable .react-bs-table-sizePerPage-dropdown .dropdown-item>a {
    display: block;
  }
  .bootstrapTable .react-bootstrap-table-pagination {
    margin: 0 !important;
  }