.hovertext {
  position: relative;
}

.hovertext:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 80px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 2px 0;
  transition: opacity 1s ease-in-out;
  position: absolute;
  z-index: 1;
  right: 10%;
  bottom: 100%;
}

.hovertext:hover:before {
  opacity: 1;
  visibility: visible;
}

.hovertextedit {
  position: relative;
}

.hovertextedit:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 90px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 2px 0;
  transition: opacity 1s ease-in-out;
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 100%;
}

.hovertextedit:hover:before {
  opacity: 1;
  visibility: visible;
}

.marginbb {
  margin-top: 1rem;
  margin-bottom: 5.2rem;
}

.expo-rt .dropdown-toggle::after {
  border-top: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  border-left: 0 !important;
}

.cpp_audio_album .exportIcon {
  width: 22px;
  height: 22px;
  color: #fff;
}

.cpp_audio_album .dropdown-toggle::after {
  display: none;
}

.cpp_audio_album .rightVideoTitle {
  width: 160px;
}


.cpp_audio_album .dateText {
  font-size: 11px;
  font-weight: bold;
  color: rgb(0, 162, 255);
}

.cpp_audio_album .bootstrapTable table>thead>tr>th:nth-child(1) {
  width: 66px;
}

.cpp_audio_album .modal-dialog {
  top: 20%;
}