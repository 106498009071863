.addaudio .css-14yr603-MuiStepContent-root {
  margin-left: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-left: none !important;
}

.addaudio .css-z7uhs0-MuiStepConnector-line {
  display: none !important;
  border-color: none !important;
  border-top-style: none !important;
  border-top-width: none !important;
}

.addaudio .css-j5w0w9-MuiStepConnector-root {
  -webkit-flex: none !important;
  -ms-flex: none !important;
  flex: none !important;
}

.addaudio .css-1bw0nnu-MuiStep-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.addaudio .css-1mz1l2x-MuiStepContent-root {
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-left: none;
}

.addaudio .css-m5vj9m-MuiStepper-root {
  display: block !important;
}

.addaudio .file-drag {
  height: 16rem;
   max-width: 100%;
  text-align: center;
  position: relative;
}

.addaudio .file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.addaudio #input-file-upload {
  justify-content: center;
    align-items: center;
    text-align-last: center;
}

.addaudio #label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.addaudio .upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.addaudio .upload-button:hover {
  text-decoration-line: underline;
}
.uplaod {
  position: relative;
  top: 25%;
}
.uplaod span{
  position: absolute;
  top: 75%;
}