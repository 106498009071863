.music-player {
  position: fixed;
  left: 0;
  width: 100%;
  height: 65px;
  z-index: 9999;
  bottom: 0;
}

.music-player-min {
  width: 150px;
  height: 150px;
  right: 19px !important;
  left: auto;
  bottom: 14px !important;
  border-radius: 20px;
}
.player-min-image {
  width: 100px;
  height: 65px;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
}

.albumArtImage {
  width: 45px;
  height: 45px !important;
  border-radius: 4px;
  object-fit: cover;
}

.playlistImage {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}

.playerTitle {
  font-size: 20px;
  line-height: 50px;
}


.playerTrackTitle {
  font-size: 13px;
  text-wrap: nowrap;
  max-width: 180px;
  overflow: hidden;
  height: 17px;
  display: block;
}

.miniPlayerTextNone {
  display: block;
  height: 10px;
  margin-top: 10px;
}
.wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 13rem;
  justify-items: stretch;
  /* animation: scroll 10s linear infinite; */
}

@keyframes scroll {
  to {
    translate: calc(-1 * 150px);
  }

}

.playerTrackSubTitle {
  font-size: 13px;
}

.musicDetails {
  color: black;
  text-align: center;
}

.progress {
  height: 2px;
  cursor: pointer;
}

.audioTime {
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  font-weight: 500;
  font-family: 'Poppins' !important;

}

.audioTime:not(:nth-child(2)) {
  width: 40px;
}

.subTitle {
  color: #fff;
  text-align: center;
  font-size: 14px;
}

.playlistAbsolute {
  width: 500px;
  background: #000;
  bottom: 61px;
  border-radius: 6px;
  padding: 12px;
  overflow: hidden;
}

.playListActive {
  background-color: rgb(84 83 83);
}

.component {
  width: 100%;
}

.defaultHeight>img {
  object-fit: contain;
}

.defaultHeightPlay {
  height: calc(100vh - 56px);
  overflow-y: scroll;
  padding-bottom: 60px;

}

.defaultHeightPlay::-webkit-scrollbar {
  height: 3px;
  width: 3px;
}

.defaultHeightPlay::-webkit-scrollbar-track {
  background-color: #888;
}

.defaultHeightPlay::-webkit-scrollbar-thumb {
  background-color: #111;
  border-radius: 20px;
}

.musicCover {
  border-radius: 10%;
  text-align: center;
}

.playButton {
  background-color: none;
  border: none;
  position: relative;
  align-items: center;
  justify-content: center;
}

.clickable {
  cursor: pointer;
}

.playerIcon {
  font-size: 28px;
  position: relative;
}


.playerVolRange {
  display: block;
}

.playerIcon:hover {
  color: #888787;
}

.playerIconMain {
  width: 32px;
  height: 35px;
}

.playButtonMain {
  border-radius: 50%;
  width: 44px;
  height: 44px;
}

.playButton.yes::before {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  top: -3px;
  right: -1px;
  content: "";
}
.playButton.one::after {
  position: absolute;
  top: -7px;
  font-size: 10px;
  font-weight: bold;
  left: -5px;
  content: "1";
}


.progress-filled-circle {
  top: -5px;
  width: 12px;
  height: 12px;
  content: "fdafas";
  border-radius: 50%;
  display: block;
  position: absolute;
  right: 0;
}

/* IE*/
.playerRange::-ms-fill-lower {
  background-color: #43e5f7;
}

.playerRange::-ms-fill-upper {
  background-color: #9a905d;
}

.playerRange::-moz-range-progress {
  background-color: #43e5f7;
}

.playerRange::-moz-range-track {
  background-color: #9a905d;
}

/*Chrome*/
.playerRange {
  /* overflow: hidden; */
  width: 100px;
  height: 3px;
  -webkit-appearance: none;
  background-color: transparent;
  border-radius: 40px;
  cursor: pointer;
  box-shadow: 0px 0px 0px 1px #777;
}

.playerRange::-webkit-slider-runnable-track {
  height: 3px;
  -webkit-appearance: none;
  color: #fff;
  margin-top: -1px;
  cursor: pointer;
  background-color: #777;
}

.playerRange::-webkit-slider-thumb {
  width: 13px;
  -webkit-appearance: none;
  height: 3px;
  cursor: pointer;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 1px #777;
}

.own-tool-tip {
  animation: jump 0.2s linear;
  z-index: 1;
}

.own-tool-tip.afternone::after {
  display: none;
}

.own-tool-tip.afternone {
  animation: open 0.2s ease-in forwards;
}

.moreAudioTrackBox {
  width: 200px;
  bottom: 50px;
  right: 13px;
}

.moreMobileButton .dropdown-toggle::after {
  display: none;

}

.music-player .dropdown-menu {
  bottom: 24px !important;
  right: 8% !important;
}

.music-player .dropdown-item:hover,
.music-player .dropdown-item:focus {
  background-color: inherit;
  /* color: inherit; */
}


@keyframes open {

  0% {
    bottom: -10px;
  }

  100% {}
}

@keyframes jump {

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* .own-tool-tip::after {
  bottom: -14px;
  content: " ";
  display: block;
  height: 6px;
  left: 0px;
  right: 0px;
  width: 4px;
  margin: 0 auto;
  position: absolute;
  border-color: #000 transparent transparent transparent;
  border-style: solid;
  border-width: 7px;
} */

.toolTipParent:hover .own-tool-tip {
  display: block !important;
}

.likeIcon {
  width: 25px;
  cursor: pointer;
  height: 26px;
}

.playButtonMain:disabled {
  opacity: 0.5;
}

@media screen and (max-width:768px) {
  .defaultHeight {
    min-height: 400px;
  }

}

@media screen and (min-width:768px) {
  .defaultHeight {
    height: calc(100vh - 56px);
  }
}

@media screen and (max-width:576px) {
  .playerIcon.mobile {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

}