.navHeading {
    font-size: 25px;
    font-weight: bold;
}

.musicImage {
    height: 154px !important;
}

.musicImageHeight {
    height: 154px !important;
}

.playCardTitle {
    font-size: 14px;
    line-height: 25px;
    font-weight: 500;
    height: 35px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.playCardDescription {
    font-size: 11px;
    font-weight: 300;
    height: 33px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.musicViewAllPageList .musicPlayButton {
    visibility: hidden;
}

.listContainer:hover .musicPlayButton {
    visibility: visible;
}

.listContainer .musicPlayButton {
    top: 55px;
    width: 55px;
    height: 55px;
}

.musicHomePageSwiper .swiper-slide:hover .musicPlayButton,
.musicAlbumSwiper .swiper-slide:hover .musicPlayButton,
.musicViewAllPageList:hover .musicPlayButton {
    visibility: visible;
    transform: scale(1);
}

.musicPlayButton {
    left: 0;
    right: 0;
    top: 60px;
    width: 50px;
    height: 50px;
    visibility: hidden;
    transform: scale(0.9);
    transition: transform 0.1s linear;
}

.musicIconHome {
    width: 35px;
    height: 35px;
}

.musicMiniLayoutIcon {
    width: 40px;
    height: 40px;
}

.musicCategoryTitle {
    top: 11px;
    left: 11px;
    font-size: 15px;
    font-weight: bold;
}

.playlistModal {
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}
.paymentppvmodalcode {
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}


.playlistIcon {
    width: 20px;
    height: 20px;
}

.playlistInput {
    padding: 12px 20px;
    height: 50px;
}

.playlistInput:focus {
    border: none;
    box-sizing: none;
    outline: none;
}

.musicDropZone {
    border-width: 2px;
    border-style: dashed;
}

.musicDropZoneIcon {
    position: absolute;
    inset: 0;
    margin: auto;
    width: 20px;
    height: 20px;
}

.text-danger-custom {
    color: #ff0000;
}

.fileNameSize {
    font-size: 12px;
}

.navHeight {
    height: 30px;
    width: 145px;
}

.musicViewTitle {
    height: 74px;
    max-width: 210px;
    margin: 0 auto;
}

.musicViewAllPageList {
    /* width: 100px; */
}

.custom-loading {
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-top-color: transparent;
    border-radius: 50%;
    animation: loading 1s linear infinite;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }

}

/* station css start */
.checkBoxStation {
    font-size: 15px;
}

.checkBoxModal {
    width: 15px;
    height: 15px;
}

.viewAllMusic {
    font-weight: 500;
}

.viewAllMusic:hover .playListDeleteButton {
    display: block;
}

.viewAllMusic.viewAllMusicHeight {
    height: 240px !important;
}

.playListDeleteButton {
    position: absolute;
    top: 7px;
    right: 7px;
    width: 30px;
    padding: 5px;
    height: 30px;
    display: none;
    border-bottom-left-radius: 8px;
}

.musicDetailsNav {
    font-size: 20px;
}

.musicPlayconfirmDeleteBox {
    bottom: 35px;
    width: 145px;
    left: 0px;
    right: 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    z-index: 1;
    animation: upside 0.2s linear forwards;
    margin: 0 auto;
}

@keyframes upside {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.deleteTextPlaylist {
    font-size: 10px;
}

.deleteIconPlaylist {
    width: 14px;
    height: 14px;
    margin: 0 10px;
}

.moreOption {
    padding: 3px;
}

.moreOption:hover+.musicDropDownWrapper {
    display: block !important;
}

.musicDropDownWrapper {
    bottom: 60px;
    right: 14px;
    width: 140px;
    height: auto;
    z-index: 1;
}


.infoBox:hover .infoContent {
    display: block;
}

.infoContent {
    top: -90px;
    left: -28px;
    line-height: 22px;
    width: 293px;
    font-size: 13px;
    display: none;
}

.infoContent::after {
    background-color: #0d6efd
}

.infoContent::after {
    width: 23px;
    height: 18px;
    position: absolute;
    content: "";
    display: block;
    left: 10px;
    bottom: -17px;
    clip-path: polygon(100% 100%, 33% 0, 100% 0%);
}

.purchaseTitle {
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    width: 200px;
    height: 20px;
    line-height: 22px;
}

.purchaseImage {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.purchaseClose {
    top: 17px;
    right: 17px;
}

.priceTagAudio {
    width: 50px;
    height: 100%;
    top: 0;
    left: 8px;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
}

.goldIcon {
    width: 20px;
    height: 20px;
    color: #ffbf00;
    fill: #ffbf00;
}

.listChip:hover .priceTagAudio {
    display: flex;
}

.purchaseHeading {
    font-size: 18px;
    font-weight: bold;
}

.purchaseInfo {
    line-height: 20px;
}

.purchaseInfo::before {
    content: '\275D';
    font-size: 20px;
}

.purchaseInfo::after {
    content: '\275E';
    font-size: 20px;
}

.musicFloatPlayer {
    width: 170px;
    z-index: 99999;
}

.musicFloatPlayerHeight {
    width: 50px;
    position: absolute;
    top: 0px;
    left: 0px;
    margin: auto;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    cursor: grab;
    z-index: 999;
    border-radius: 4px;
}

.musicFloatPlayerTitle {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    overflow: hidden;
    height: 20px;
    width: 80px;
}

.miniFloatPlayerMinimize {
    position: absolute;
    bottom: 6px;
    right: 7px;
    cursor: pointer;
    z-index: 999;
}

.miniFloatPlayerDetails {
    position: absolute;
    top: 6px;
    right: 7px;
    cursor: pointer;
    z-index: 999;
}

.layoutPlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
}

.listChip:hover .layoutPlay {
    display: flex;
}

.layoutRight>span:not(.priceLabelViewPage, .othersTime) {
    width: 40px;
}

.after::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    left: 0;
}

/* loader css start */
.emptyImageLoader {
    width: 50px;
    height: 50px;
}

.playlistLoader p {
    width: 150px;
    height: 20px;
}

.playlistLoader span {
    width: 30px;
    height: 30px;
}

.sideBarInfo {
    width: 250px;
    top: -100px;
    left: 0;
    animation: opacityAni 0.6s linear;
}

.sideBarInfoText {
    font-size: 13px;
    line-height: 22px;
}

@keyframes opacityAni {
    0% {
        opacity: 0.2;

    }

    100% {
        opacity: 1;
    }

}

.sideBarInfo::after {
    width: 23px;
    height: 18px;
    position: absolute;
    content: "";
    display: block;
    left: 1px;
    bottom: -17px;
    clip-path: polygon(100% 100%, 33% 0, 100% 0%);
    background-color: #0d6efd;
}

.searchPageIcon {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 25px;
    height: 25px;
    z-index: 1;
}

.focus-out-none:focus {
    outline: none;

}

.scrollingPlaceholder {
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 16px;
    box-sizing: border-box;
    position: relative;
}

.scrollingPlaceholder::placeholder {
    opacity: 0.6;
}

.scrollingPlaceholder::placeholder,
.placeholderText {
    font-size: 15px;

}

.closeBar {
    top: 18px;
    right: 3%;
}

.placeholderWrapper {
    height: 55px;
    overflow: hidden;
    position: relative;
}

.placeholderText {
    position: absolute;
    top: 100%;
    left: 19%;
    opacity: 0;
    transition: top 0.8s ease, opacity 0.8s ease;
}

.placeholderText.active {
    top: 18px;
    opacity: 1;
}

.placeholderText.prev {
    top: -100%;
    opacity: 0;
}

.hoverEffectText {
    font-size: 12px;
    font-weight: 300;
}

.musicLogoImage {
    width: 30px !important;
    height: 30px !important;
}

.musicLogoImageHeight {
    width: 50px !important;
    height: 50px !important;
}

.musicLogoImageUser {
    width: 35px !important;
    height: 35px !important;
}

.navbarWrapper {
    height: 66px;
}

.musicSearch {
    max-width: 300px;
    width: 300px;
    padding-left: 37px;
    margin-left: 15px;
    font-size: 16px;
}

.musicSearch:focus {
    border: none;
    outline: none;
}

.musicSearch::placeholder {
    font-size: 13px;
}

.profileNavigation,
.profileToggle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

/* loader css end */
@media screen and (max-width:768px) {
    .navHeading {
        font-size: 18px;
    }
}

@media screen and (max-width:375px) {
    .navHeading {
        font-size: 12px;
    }
}