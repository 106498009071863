.themeTwoHeader {
    top: 0;
    left: 0;
    z-index: 9998;
}

.themeTwoAvatar {
    width: 35px;
    height: 35px !important;
}

/* .activeBarSlash::after {
    position: absolute;
    content: "";
    bottom: 6px;
    left: 0;
    height: 3px;
    border-radius: 20px;
    width: 20px;
} */

.themeTwoLink.active::after {
    position: absolute;
    content: "";
    bottom: 6px;
    left: 0;
    height: 3px;
    border-radius: 20px;
    width: 20px;
}

.themeTwoLink:hover:after {
    position: absolute;
    content: "";
    bottom: 6px;
    left: 0;
    height: 3px;
    border-radius: 20px;
    width: 20px;
}

.themeTwoLink {
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
}

.searchBarThemeTwo {
    top: 12px;
    left: 12px;
}

.priceTableBigHead {
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
}

.priceTableHead {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
}

.priceTableSub {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;

}

.planTable thead th {
    width: 150px;
}

.planDevice {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
}

.borderRadius {
    border-radius: 30px;
}

.themeTwoBannerWrapper.plan {
    /* height: 100vh; */
}

.themeTwoBannerWrapper>div {
    overflow-y: scroll;
    height: 100%;
    padding: 30px 10px;
}

.themeTwoBannerWrapper>div::-webkit-scrollbar {
    display: none;
}

.tickBtn {
    width: 30px;
    height: 30px;
}

.planDuration {
    width: 230px;

}

.planDuration>span {
    font-size: 20px;
    line-height: 36px;
    font-weight: 500;
}

.monthCalculate {
    font-size: 13px
}

.planChooseHead {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
}

.planChoose {
    width: 210px;
}

.themeTwoFooter {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
}

.footerHeadingThemeTwo {
    font-size: 20px;
    line-height: 40px;
    font-weight: 600;
}

.footerNavThemeTwo {
    font-size: 14px;
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 10px;
}

.footerSocialLinkThemeTwo {
    width: 25px;
    height: 25px;
}

.popupDetailsThemeTwo {
    top: 20x;
    right: 5px;
    width: 270px;
    display: none;
    border-top-right-radius: 0px !important;
}

.profileImageThemeTwo:hover .popupDetailsThemeTwo {
    display: block;
}

.popupDetailsThemeTwo::after {
    content: "";
    top: 5px;
    right: 0;
    width: 26px;
    height: 19px;
    position: absolute;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.popupDetailLink {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    opacity: 0.5;
}

.popupModal {
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.7);
    background-image: none !important;
}

.closeBtnModal {
    top: 18px;
    right: 18px;
    z-index: 1;
}

/* .planWrapper table {
    width: 1200px;
 
} */