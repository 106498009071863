.swiperThemeTwo {
    width: 100%;
    aspect-ratio: 16/9;
    position: relative;
    /* height: calc(100vh - 72px);
    padding-bottom: 62px; */
    height: calc(100vh - 80px);
    padding-bottom: 40px;
}

.swiperThemeTwo .swiper-slide {
    height: 100%;
    position: relative;
}

.swiperThemeTwo .bannerWrapper {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.swiperThemeTwo .swiper-pagination-bullet {
    opacity: 1 !important;
}

.swiperThemeTwo .swiper-pagination-bullet>img {
    width: 100%;
    height: 100%;
}


.swiperThemeTwo .swiper-pagination {
    bottom: 0% !important;
    width: 63% !important;
}

.swiperThemeTwo .swiper-pagination-bullet-active-prev,
.swiperThemeTwo .swiper-pagination-bullet-active-prev-prev,
.swiperThemeTwo .swiper-pagination-bullet-active-next,
.swiperThemeTwo .swiper-pagination-bullet-active-next-next {
    transform: scale(0.94) !important;
}

.swiperThemeTwo .swiper-button-prev {
    left: 23%;
}

.swiperThemeTwo .swiper-button-next {
    right: 23%;
}

.swiperThemeTwo .swiper-button-prev,
.swiperThemeTwo .swiper-button-next {
    top: 93%;
    z-index: 11;
}

.swiperThemeTwo .swiper-button-prev::after,
.swiperThemeTwo .swiper-button-next::after {
    font-size: 22px;
}

.swiperThemeTwo .swiper-pagination-bullet.swiper-pagination-bullet-active.swiper-pagination-bullet-active-main {
    transform: scale(1);
}

.swiperThemeTwo .swiperHeading {
    font-size: 28px;
    line-height: 53px;
    font-weight: bold;
}

.swiperThemeTwo .listSub {
    font-size: 17px;
    line-height: 30px;
    font-weight: 500;
    position: relative;
}

.swiperThemeTwo .circleAfter::after {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0;
    margin: auto;
    left: -10px;
}

.swiperThemeTwo .bannerDescription {
    font-size: 15px;
    line-height: 23px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.bannerSwiperBtn {
    font-size: 15px;
    line-height: 30px;
    font-weight: 600;
}

.swiperThemeTwo .bannerSwiperBtn:not(:nth-child(1)) {
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.swiperThemeTwo video {
    object-fit: cover !important;
}

.swiperThemeTwo .overlayAbsoluteVideo {
    top: 0;
    left: 0;
    padding: 90px 60px;
}

.swiperThemeTwo .vjs-poster {
    background-size: cover;
}