/*--------------------------------------------------------------
Channel Sidebar Start
--------------------------------------------------------------*/
.sideMenu {
  padding: 10px 15px;
  cursor: pointer;
}

.sideMenu:active,
.sideMenu:focus,
.sideMenu:hover {
  color: inherit;
  text-decoration: none !important;
}

/*--------------------------------------------------------------
Channel Sidebar End
--------------------------------------------------------------*/