.audioBanner {
  width: 100%;
  min-height: 306px;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}

.audioBanner::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.opacityColor {
  opacity: 0.5;
}

.audioWishList {
  width: 35px;
  height: 35px;
}

.audioBannerPlay {
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50%;
}

.audioText {
  font-size: 18px;
}

.audioBorder {
  border: 1px solid #eeeeee1a;
}

.topArtist {
  border-radius: 12px;
}

.audioArtists {
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins";
}

.audioArtistCard {
  border-radius: 7px;
  position: relative;
  width: 100%;
  height: auto;
}

.artistImage {
  width: 100%;
  height: 93px;
  object-fit: cover;
  border-radius: 5px;
}

.artistsCardTitle {
  font-size: 12px;
  font-weight: 500;
  display: block;
  line-height: 13px;
  text-transform: capitalize;
  white-space: nowrap;
  cursor: pointer;
  height: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.artistPlayButton {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  animation: popupsmooth 0.3s linear forwards;
}

.topArtistsContainer .swiper-button-next:after,
.topArtistsContainer .swiper-button-prev:after {
  font-size: 12px;
  font-weight: bold;
  color: #000;
}

.topArtistsContainer:hover .swiper-button-next,
.topArtistsContainer:hover .swiper-button-prev {
  opacity: 1;
}

.topArtistsContainer .swiper-button-next,
.topArtistsContainer .swiper-button-prev {
  background-color: #fff;
  height: 26px;
  border-radius: 30px;
  opacity: 0;
}

.topArtistsContainer .swiper-button-next.swiper-button-disabled,
.topArtistsContainer .swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

@keyframes popupsmooth {
  0% {
    transform: scale(0.2);
  }

  100% {
    transform: scale(1);
  }
}

.artistBtnPlay {
  width: 14px;
  height: 15px;
}

.audioArtistCard:hover .artistPlayButton {
  display: flex;
}

.listenCard {
  border-radius: 7px;
}

.borderBottom {
  border-bottom: 1px solid #eeeeee1a;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(78, 78, 78, 1) 50%,
    rgba(78, 78, 78, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.audioImage {
  width: 50px;
  height: 50px !important;
  object-fit: cover;
  border-radius: 7px;
}

.audioListText {
  font-size: 14px;
  overflow: hidden;
  font-weight: 400;
  height: 23px;
  width: 100%;
  font-family: "Poppins";
  margin-bottom: 0;
  word-wrap: break-word;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.audioListName {
  font-size: 11px;
  font-weight: 300;
  font-family: "Poppins";
  height: 15px;
  word-wrap: break-word;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.audioListNameTrack {
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  font-family: "Poppins";
}

.listChip:hover .moreOption {
  visibility: visible;
}

.listChip:hover .ListplayButton .numberList {
  display: none;
}

.listChip:hover .ListplayButton .buttonList {
  display: block;
}

.ListplayButton .numberList {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListplayButton .buttonList {
  display: none;
}

.moreOption {
  visibility: hidden;
}

.ListplayButton {
  width: 40px;
  overflow: hidden;
  text-align: center;
}

.widthAudio {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playIconForActive {
  width: 22px;
  top: 13px;
  margin: 0 auto;
  left: 0;
  height: auto;
  position: absolute;
  right: 0;
}

.audioPlayLeftImage {
  max-width: 336px;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}

.playerDescription {
  font-size: 17px;
  font-family: "Poppins";
}

.smallIcons {
  width: 16px;
  height: 16px;
}

.audioBannerDesc {
  font-family: "Poppins";
}

.audioViews {
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins";
}

.linearShadow {
  position: relative;
}

.linearShadow::before {
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

.myBackground {
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.userVisible {
  font-size: 28px;
  font-weight: 400;
  text-align: center;
}

.userVisibleText {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

.smallText {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.pageWrapper {
  width: 100%;
  min-height: 305px;
}

.pageWrapper2 {
  width: 100%;
  min-height: 205px;
}

.midHeading {
  font-family: "Poppins";
  text-transform: capitalize;
  font-size: 55px;
  font-weight: 600;
  line-height: 66px;
  letter-spacing: 0em;
}

.midHeading2 {
  text-transform: capitalize;
  font-size: 27px;
  font-weight: 600;
  line-height: 51px;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  background-color: white;
}

.vl {
  /* border-left: 6px solid red; */
  height: 50px;
}

.smallText1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.descript {
  font-size: 15px;
  font-weight: 300;
  line-height: 27px;
}

.descript.hide {
  height: 135px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.expendedHeight {
  height: 78px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.expendedHeight2 {
  min-height: 105px;
}

.smallText.extraBold {
  font-weight: 600;
  cursor: pointer;
}

.shareBox {
  position: absolute;
  bottom: 38px;
  left: -10000%;
  transition: left 0.5s ease-in-out;
}

.shareContainer:hover .shareBox {
  left: 0;
}

.shareBox {
  border-radius: 6px;
  box-shadow: 12px 5px 26px #000;
  min-height: 80px;
  background-color: #fff;
  min-width: 200px;
}

.shareHeadingText {
  color: #000;
  font-size: 18px;
  font-weight: 500;
}

.shareIconBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.shareIconBox > img {
  width: 16px;
  height: 16px;
}

.shareIconBoxText {
  color: #2c2c2c;
  font-size: 10px;
  padding: 0px 4px;
  margin-top: 7px;
  text-align: center;
  font-weight: 600;
}

.shareIconBox > svg {
  width: 24px;
  height: 24px;
  color: #000;
}

.shareIconBox:hover .shareIconBoxText,
.shareIconBox:hover > svg {
  color: #eb125e;
}

.detailsSideImage {
  border-radius: 5px;
  height: 394px;
  width: 100%;
  object-fit: cover;
}

.seriesText {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  position: relative;
}

.seasonText {
  font-family: "Poppins";
  font-size: 25px;
  font-weight: 600;
  line-height: 53px;
  letter-spacing: 0em;
  position: relative;
}

.backgroundLang {
  /* width: 100%; */
  height: 100%;
  background-color: #2c2c2c;
  border-radius: 23px;
  margin-right: 20px;
  text-align: center;
  padding: 8px;
}

.videoTitleImg {
  width: 267px;
  height: 124px;
}

.beforeCircle::after {
  width: 5px;
  height: 5px;
  background-color: #fff;
  border-radius: 50%;
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0;
  margin: auto;
  right: -16px;
}

.beforeLine::after {
  width: 0px;
  height: 45%;
  content: "";
  position: absolute;
  top: 7px;
  right: -16px;
  border: 1px inset;
}

.beforeCircleNone::after {
  display: none;
}

.defaultIconStyle {
  font-size: 25px;
  cursor: pointer;
}

.watchlaterIconStyle {
  width: 25px;
  height: 25px;
  font-weight: bold;
  cursor: pointer;
}

.pageWrapperChannel {
  width: 100%;
  min-height: 255px;
}

.channelLogo {
  position: relative;
  z-index: 1;
  top: -60px;
  left: 50px;
}

.channelShare {
  position: relative;
  z-index: 1;
  left: 50px;
  bottom: 30px;
  width: 40px;
}

.channelTab {
  position: relative;
  z-index: 1;
  left: 50px;
}

.channelImage {
  object-fit: cover;
  border: 5px solid #fff;
  -webkit-box-shadow: 10px 10px 121px 66px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 121px 66px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 121px 66px rgba(0, 0, 0, 0.75);
}

.no-episodes-message {
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
}

.episodeHeading {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
}

.row-custom {
  /* background-color: rgba(0, 0, 0, 0.4); */
  --bs-gutter-x: 0;
  border-radius: 8px;
  background-color: #3b3b3b;
}

.row-custom:hover {
  background-color: rgba(48, 48, 48, 0.829);
}

/* .row-custom:hover img {
  transform: scale(1.1); 
} */
.episodeImageD {
  max-height: 174px !important;
  transition: transform 0.3s ease-in-out;
  object-fit: cover;
  display: block;
  width: 100%;
  border-radius: 25px;
}

.episodeImageContainer {
  overflow: hidden;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
  position: relative;
}
/* .episodeImageContainer:hover img {
  transform: scale(1.1); 
} */
/* .episodeImageContainer:hover .playIconContainer {
  opacity: 1;
} */

.episodeImageContainer:hover .playIconContainer {
  /* transform: translate(-50%, -50%) scale(1);  */
  transform: translate(-50%, -50%) scale(1.1);
  opacity: 1; /* Fade in on hover */
}
.playIcon {
  font-size: 40px;
  color:white;
  opacity: 0.8;
  /* animation: pulseAndScale 1.5s infinite ease-in-out; */
  animation: pulse 1s infinite ease-in-out; 
  /* animation: bounce 1s infinite; */
  /* animation: rotate 2s linear infinite; */
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes pulseAndScale {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.2); /* Scale up to 1.2 times */
    opacity: 1; /* Full opacity */
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.episodeContainer .playIconContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 10;
}

.episodeContainer:hover .playIconContainer {
  display: block;
}

/* .episodeContainer:hover {
  background-color: #3b3b3b;
} */

.episodeImageContainer {
  position: relative;
}

.beforeCircle:last-child::after {
  display: none;
}

.beforeLine:last-child::after {
  display: none;
}

.viewDetailsText {
  width: 90px;
}

.musicDescriptions {
  height: 30px;
  line-height: 15px;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.audioBannerDescription {
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  overflow: hidden;
  height: 112px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.buttonList > svg {
  width: 30px;
  height: 20px;
}

.detailsList::after {
  content: ":";
  padding-left: 5px;
}

.videoDetailsWrapper {
  min-height: calc(100vh - 100px);
}

.detailsNav {
  font-size: 16px;
  line-height: 29px;
  font-weight: 600;
}

.partnerBanner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  aspect-ratio: 16/6;
}

.partnerSwiper {
  aspect-ratio: 16/6;
}

.partnerBanner .bannerContainer {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.partnerSwiper .swiper-pagination-bullet {
  opacity: 1;
}

.partnerSwiper .swiper-pagination {
  bottom: 0;
}

.partnerHeading {
  font-size: 30px;
  line-height: 55px;
  font-weight: bold;
}

.partnerDes {
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
}

.partnerSmall {
  font-size: 13px;
  line-height: 26px;
  font-weight: 300;
}

.partnerImage {
  aspect-ratio: 16/9;
}

.partnerBellIcon {
  /* background-color: rgba(0, 0, 0, 0.5); */
}

@media screen and (max-width: 767px) {
  .audioBannerHeading {
    font-size: 30px;
  }

  .audioBanner {
    min-height: 250px;
  }

  .audioBannerPlay {
    width: 45px;
    height: 45px;
  }

  .audioWishList {
    width: 30px;
    height: 30px;
  }

  /* .othersTime {
    width: 34px;
    font-size: 12px;
    line-height: 13px;
  } */

  .audioListText {
    font-size: 13px;
    width: 100px;
  }

  .clockImage {
    width: 15px;
    height: 15px;
  }

  .midHeading {
    font-size: 38px;
  }

  .midHeading2 {
    font-size: 20px;
    line-height: 30px;
  }

  .audioText {
    font-size: 14px;
  }

  .detailsNav {
    font-size: 14px;
    line-height: 25px;
    font-weight: 600;
  }
}

@media screen and (max-width: 425px) {
  .smallText1 {
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) {
  .audioBannerHeading {
    font-size: 40px;
    font-weight: bold;
  }

  .audioBannerWidth {
    width: 50%;
  }
}

/* Modal backdrop */
.modal-backdrop-rent {
  /* position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  z-index: 999;
  /* Set a high z-index for the backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal content */
.modal-content-rent {
  /* background-color: rgb(209, 144, 144);
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  max-width: 100%;
  text-align: center; */
  background-color: rgb(50, 53, 50);
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
  /* Ensure content has a higher z-index than the backdrop */
  width: 850px;
  max-width: 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Close button */
.close-btn-rent {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
}

.modal-open {
  overflow: hidden;
}
