.rtl .userListBox {
    left: 0;
    right: auto;
}

.rtl .headerSubLink {
    text-align: right;
}

.rtl .dropdown-item {
    text-align: right;
}

.rtl .searchInputHeader {
    right: auto;
    left: 0;
}

.rtl .homeNavLink {
    text-align: right;
}

.rtl .homeNavDropDown {
    padding: 0;
}

.rtl .float-end {
    float: left !important;
}

.rtl .priceLabel {
    border-top-left-radius: 2px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 2px;
    right: 0;
    left: auto;
}

.rtl .homePageSwiper .swiper-slide-active .homeSwiperContainer {
    right: 25px;
}

.rtl .beforeCircle::after {
    display: none;

}

.rtl .beforeCircle::before {
    width: 5px;
    height: 5px;
    background-color: #fff;
    border-radius: 50%;
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0;
    margin: auto;
    right: -16px;
}

.rtl .beforeCircle:first-child::before {
    display: none;
}

.rtl .me-4 {
    margin-left: 1.5rem !important;
    margin-right: 0px !important;
}

.rtl .playerPrev,
.rtl .navigationNext,
.rtl .navigationPrev,
.rtl .playButtonMain.triangle,
.rtl .buttonList,
.rtl .audioBannerPlay,
.rtl .musicPlayButton,
.rtl .arrowIconModal {
    transform: rotate(180deg) !important;
}

.rtl .playerNext {
    transform: rotate(0deg) !important;
}

.rtl .me-5 {
    margin-left: 3rem !important;
    margin-right: 0px !important;
}

.rtl .shareContainer:hover .shareBox {
    right: 0;
    left: auto;
}

.rtl .shareBox {
    right: -10000%;
    left: auto;
    transition: right 0.5s ease-in-out;
}

.rtl .bannerPlayBtn {
    justify-content: start !important;
}

.rtl .volumnButtonImage {
    right: 15px;
    left: auto;
}

.rtl .me-2 {
    margin-right: 0rem !important;
    margin-left: 0.5rem !important;
}

.rtl .signInMenu .eyeButton {
    left: 4px;
    right: auto;
}

.rtl .flickNexsDateShow {
    right: 0;
    left: auto;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 7px;
}

.rtl .flickNexsDateShow::before {
    right: 0;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
}

.rtl .flicknexsDayMaker .flickNexsDateShow::after {
    right: 0;
}

.rtl .timeValues {
    left: auto;
    right: -20px;
}

.rtl .flickNexs24Hours:nth-child(2) .timeValues {
    top: 4px;
    right: 0px;
    direction: rtl;
}

.rtl .flex-reverse {
    flex-direction: row-reverse;
}


.rtl .account-label {
    text-align: right;
}


.rtl .faqSearchThemeTwo {
    left: 0px;
    right: 15px;
}

.rtl .themeTwoFaq .accordion-button::after {
    margin-right: auto;
    margin-left: unset;
}

.rtl .ps-3 {
    padding-right: 1rem !important;
}

.rtl .pe-5 {
    padding-left: 3rem !important;
}

.rtl .closeBtnModal {
    left: 18px;
    right: auto;
}

.rtl .ps-5 {
    padding-right: 3rem !important;
    padding-left: 0px !important;
}

.rtl .searchPageIcon {
    right: 16px;
    left: auto;
}

.rtl .placeholderText {
    right: 19%;
    left: auto;
}

@media screen and (min-width: 1200px) {
    .rtl .homePageSwiper .swiper-slide-next+.swiper-slide+.swiper-slide+.swiper-slide+.swiper-slide .homeSwiperContainer {
        right: auto;
        left: 24px;
    }
}

@media screen and (max-width: 1200px) {
    .rtl .homeNavbarMobileFixed.active {
        right: 0;
        left: auto;
    }

    .rtl .homeNavbarMobileFixed {
        right: -100%;
        left: auto;
        transition: right 0.4s ease-out;
    }

    .rtl .homeNavSlide {
        padding: 0;
    }
}

@media screen and (min-width: 1400px) {
    .rtl .viewAllCardTwo:nth-child(7n-6):hover .hoverCardContainer {
        left: auto;
        right: 45px;
    }

    .rtl .viewAllCardTwo:nth-child(7n+0):hover .hoverCardContainer {
        right: auto;
        left: 45px;
    }

}

/* 
.rtl .gridCategoryArrowImage {
    -webkit-box-reflect: right;
}

.rtl .gridCategoryItems:hover .gridCategoryArrowImage {
    -webkit-box-reflect: right;
} */